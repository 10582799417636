import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/css/Spotlight.css';



function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-prev" onClick={onClick}>
      Previous
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-next" onClick={onClick}>
      Next
    </button>
  );
}

const Spotlight = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
<div className='bacimage'>
    <section className='banner'>
      <Slider {...settings}>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 1" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner3.jpg" alt="Banner 2" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 3" />
        </div>
      </Slider>
    </section>


    <div className="event" style={{marginTop:'20px'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <nav className="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s"
               style={{ visibility: 'visible', animationName: 'fadeInDown' }}>

            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active show" id="nav-home-tab" data-toggle="tab" href="#one" role="tab"
                 aria-selected="true">
                <div className="nav-content">
                  <strong>Special Projects</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#two" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>News</strong>
                 
                </div>
              </a>
              
            </div>

          </nav>
          </div>
          </div>

          <div className="row">
          <div className="col-lg-12 col-md-12">
          <div className="tab-content wow fadeInDown animated"
      data-animation="fadeInDown animated" data-delay=".2s" id="nav-tabContent"
      style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
      <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="nav-home-tab">
      <section id="about" className="about">
    <div className="container">
    <h1 style={{textAlign:'center',marginBottom:'50px',marginTop:'-25px'}}>Mind Magazine </h1>

      <div className="row">
      
        <div className="col-lg-6" data-aos="zoom-in">
          <img src="assets/img/about.jpg" className="img-fluid" alt="" style={{borderRadius:'20px 20px 20px 130px'}}></img>
        </div>
        <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
          <div className="content pt-4 pt-lg-0">
            <h3>Mind Magazine Latest Issue</h3>
            <p className="font-italic">
            "THE MENSA INDIA MAGAZINE"
            </p>
            <ul>
              <li><i className="icofont-check-circled"></i>  "Hello Mensans,"
The cover photo this time is by one of our youngest members in the country. I am assuming this is perhaps her first submission after becoming a member. She had made the submission with all due details that we request, which even several older members forget to include along with their submissions. “This one is going on the cover page this time,” I thought....</li>
             
            </ul>
            <button className="btn btn-primary" onClick={() => window.location.href = ''}>
  Read More
</button>
          </div>
        </div>
      </div>

      <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2> MENSA INDIA HQ </h2>
          <p></p>
        </div>

        <div className="row">

        <Slider {...settings2}>

          <div className="">
            <div className="member" data-aos="zoom-in">
              <div className="pic"><img src="assets/img/team/team-1.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Dr. Girish Bapat</h4>
                <span>Chairman, Mensa India & Chairman, JPIP</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="200">
              <div className="pic"><img src="assets/img/team/team-3.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>CTO</span>
                 
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          </Slider>

        </div>

      </div>
    </section>

    </div>
  </section>
      </div>

      <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="nav-profile-tab">
      <section className="section">
        <div className="container">
            <div className="justify-content-center row">
                <div className="col-lg-12">
                    <div className="candidate-list-widgets mb-4">
                        <form action="#">
                            <div className="g-2 row">
                               
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="align-items-center row">
                        <div className="col-lg-8">
                            <div className="mb-3 mb-lg-0">
                                <h6 className="fs-16 mb-0">Showing 1 – 8 of 11 results</h6>
                            </div>
                        </div>
                        
                        <div className="col-lg-4">
                            <div className="candidate-list-widgets">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="selection-widget">
                                            <select className="form-select" data-trigger="true"
                                                name="choices-single-filter-orderby" id="choices-single-filter-orderby"
                                                aria-label="Default select example">
                                                <option value="df">Default</option>
                                                <option value="ne">Newest</option>
                                                <option value="od">Oldest</option>
                                               
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="selection-widget mt-2 mt-lg-0">
                                            <select className="form-select" data-trigger="true"
                                                name="choices-candidate-page" id="choices-candidate-page"
                                                aria-label="Default select example">
                                                <option value="df">All</option>
                                                <option value="ne">8 per Page</option>
                                                <option value="ne">12 per Page</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="candidate-list">
                        <div className="candidate-list-box card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">Charles Dickens</a><span
                                                    className="badge bg-success ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">Project Manager</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane Richardson</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                            <span className="badge bg-soft-secondary fs-14 mt-1">Leader</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Manager</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Developer</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="candidate-list-box bookmark-post card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar2.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">Gabriel Palmer</a><span
                                                    className="badge bg-warning ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">HTML Developer</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane California</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1"><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Design</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Developer</span></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="candidate-list-box card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar3.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">Rebecca Swartz </a><span
                                                    className="badge bg-success ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">Graphic Designer</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane Richardson</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1"><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Design</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Developer</span></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="candidate-list-box bookmark-post card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar4.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">Betty Richards</a><span
                                                    className="badge bg-success ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">Education Training</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane Richardson</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                            <span className="badge bg-soft-secondary fs-14 mt-1">Trainer</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Adobe illustrator</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="candidate-list-box card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar5.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">Jeffrey Montgomery</a><span
                                                    className="badge bg-success ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">Restaurant Team Member</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane Richardson</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                            <span className="badge bg-soft-secondary fs-14 mt-1">Trainer</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Adobe illustrator</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="candidate-list-box card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar6.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">Milton Osborn</a><span
                                                    className="badge bg-danger ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">Assistant / Store Keeper</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane Richardson</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                            <span className="badge bg-soft-secondary fs-14 mt-1">Trainer</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Adobe illustrator</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="candidate-list-box card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">Harold Jordan</a><span
                                                    className="badge bg-success ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">Executive, HR Operations</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane Richardson</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                            <span className="badge bg-soft-secondary fs-14 mt-1">Trainer</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Adobe illustrator</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="candidate-list-box card mt-4">
                            <div className="p-4 card-body">
                                <div className="align-items-center row">
                                    <div className="col-auto">
                                        <div className="candidate-list-images">
                                            <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar8.png"
                                                    className="avatar-md img-thumbnail rounded-circle" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="candidate-list-content mt-3 mt-lg-0">
                                            <h5 className="fs-19 mb-0">
                                                <a className="primary-link" href="#">MichaeL Drake </a><span
                                                    className="badge bg-warning ms-1"></span>
                                            </h5>
                                            <p className="text-muted mb-2">Full Stack Engineer</p>
                                            <ul className="list-inline mb-0 text-muted">
                                                <li className="list-inline-item"><i className="mdi mdi-map-marker"></i> Oakridge
                                                    Lane Richardson</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                            <span className="badge bg-soft-secondary fs-14 mt-1">Trainer</span><span
                                                className="badge bg-soft-secondary fs-14 mt-1">Adobe illustrator</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="mt-4 pt-2 col-lg-12">
                    <nav aria-label="Page navigation example">
                        <div className="pagination job-pagination mb-0 justify-content-center">
                            <li className="page-item disabled">
                                
                            </li>
                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">4</a></li>
                            <li className="page-item">
                               
                            </li>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    </div>

   


  


    </div>
        </div>
      </div>
    </div>



    </div>



    </div>
  );
};

  

 


export default Spotlight;
