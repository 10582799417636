import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/css/Chapter.css';



function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-prev" onClick={onClick}>
      Previous
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-next" onClick={onClick}>
      Next
    </button>
  );
}

const Chapter = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  

  return (
<div className='bacimage'>
    <section className='banner'>
      <Slider {...settings}>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 1" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner3.jpg" alt="Banner 2" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 3" />
        </div>
      </Slider>
    </section>


    <div className="event" style={{marginTop:'20px'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <nav className="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s"
               style={{ visibility: 'visible', animationName: 'fadeInDown' }}>

            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active show" id="nav-home-tab" data-toggle="tab" href="#one" role="tab"
                 aria-selected="true">
                <div className="nav-content">
                  <strong>Chapter</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#two" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>SIG</strong>
                 
                </div>
              </a>
              
            </div>

          </nav>
          </div>
          </div>

          <div className="row">
          <div className="col-lg-12 col-md-12">
          <div className="tab-content wow fadeInDown animated"
      data-animation="fadeInDown animated" data-delay=".2s" id="nav-tabContent"
      style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
      <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="nav-home-tab">
      <section id="about" className="about">
    <div className="container">
    <h1 style={{textAlign:'center',marginBottom:'50px',marginTop:'-25px'}}>Mind Magazine </h1>
      <div className="row">
      
        <div className="col-lg-6" data-aos="zoom-in">
          <img src="assets/img/about.jpg" className="img-fluid" alt="" style={{borderRadius:'20px 20px 20px 130px'}}></img>
        </div>
        <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
          <div className="content pt-4 pt-lg-0">
            <h3>Mind Magazine Latest Issue</h3>
            <p className="font-italic">
            "THE MENSA INDIA MAGAZINE"
            </p>
            <ul>
              <li><i className="icofont-check-circled"></i>  "Hello Mensans,"
The cover photo this time is by one of our youngest members in the country. I am assuming this is perhaps her first submission after becoming a member. She had made the submission with all due details that we request, which even several older members forget to include along with their submissions. “This one is going on the cover page this time,” I thought....</li>
             
            </ul>
            <button className="btn btn-primary" onClick={() => window.location.href = ''}>
  Read More
</button>
          </div>
        </div>
      </div>

    </div>
  </section>


  <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2> MENSA INDIA HQ </h2>
          <p></p>
        </div>

        <div className="row">

        <Slider {...settings2}>

          <div className="">
            <div className="member" data-aos="zoom-in">
              <div className="pic"><img src="assets/img/team/team-1.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Dr. Girish Bapat</h4>
                <span>Chairman, Mensa India & Chairman, JPIP</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="200">
              <div className="pic"><img src="assets/img/team/team-3.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>CTO</span>
                 
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          </Slider>

        </div>

      </div>
    </section>


    <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2> MENSA INDIA HQ </h2>
          <p></p>
        </div>

        <div className="row">

        <Slider {...settings3}>

          <div className="">
            <div className="member" data-aos="zoom-in">
              <div className="pic"><img src="assets/img/team/team-1.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Chief Executive Officer</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="200">
              <div className="pic"><img src="assets/img/team/team-3.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>CTO</span>
                 
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          </Slider>

        </div>

      </div>
    </section>


  


   



      </div>

      <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="nav-profile-tab">

      <div className='container'>
      <div className='row'>


      <div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>
        
      <a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

  <svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <image href="https://mensaindia.org/new/assets/img/member/sig/paint.svg" width="71" height="76" x="30" y="20" />
</svg>

  </div>
  <p>MI Art and Design</p>
</a>

</div>

<div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>

<a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

<svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(30.000000, 14.000000)">
        <g id="Group-8" fill="#D98A19">
            <g id="Group-7">
                <g id="Group-6">
                    <path d="M0,0 L0,75.9204805 L69.1511499,75.9204805 L0,0 Z M14.0563973,32.2825679 L42.9457663,63.9991501 L14.2315268,63.9991501 L14.0563973,32.2825679 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
        <g id="Group-20" transform="translate(0.000000, 14.114286)" stroke="#FFFFFF" strokeLinecap="square">
            <path d="M0.419998734,54.9642857 L4.70316223,54.9642857" id="Line"></path>
            <path d="M0.419998734,50.4404762 L4.70316223,50.4404762" id="Line"></path>
            <path d="M0.419998734,45.9166667 L4.70316223,45.9166667" id="Line"></path>
            <path d="M0.419998734,41.3928571 L2.93999114,41.3928571" id="Line"></path>
            <path d="M0.419998734,36.8690476 L4.70316223,36.8690476" id="Line"></path>
            <path d="M0.419998734,32.3452381 L4.70316223,32.3452381" id="Line"></path>
            <path d="M0.419998734,27.8214286 L4.70316223,27.8214286" id="Line"></path>
            <path d="M0.419998734,23.297619 L2.93999114,23.297619" id="Line"></path>
            <path d="M0.419998734,18.7738095 L4.70316223,18.7738095" id="Line"></path>
            <path d="M0.419998734,14.25 L4.70316223,14.25" id="Line"></path>
            <path d="M0.419998734,9.72619048 L4.70316223,9.72619048" id="Line"></path>
            <path d="M0.419998734,5.20238095 L2.93999114,5.20238095" id="Line"></path>
            <path d="M0.419998734,0.678571429 L4.70316223,0.678571429" id="Line"></path>
        </g>
    </g>
</svg>
  </div>
  <p>Education</p>
</a>

</div>

<div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>

<a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

<svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(30.000000, 14.000000)">
        <g id="Group-8" fill="#D98A19">
            <g id="Group-7">
                <g id="Group-6">
                    <path d="M0,0 L0,75.9204805 L69.1511499,75.9204805 L0,0 Z M14.0563973,32.2825679 L42.9457663,63.9991501 L14.2315268,63.9991501 L14.0563973,32.2825679 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
        <g id="Group-20" transform="translate(0.000000, 14.114286)" stroke="#FFFFFF" strokeLinecap="square">
            <path d="M0.419998734,54.9642857 L4.70316223,54.9642857" id="Line"></path>
            <path d="M0.419998734,50.4404762 L4.70316223,50.4404762" id="Line"></path>
            <path d="M0.419998734,45.9166667 L4.70316223,45.9166667" id="Line"></path>
            <path d="M0.419998734,41.3928571 L2.93999114,41.3928571" id="Line"></path>
            <path d="M0.419998734,36.8690476 L4.70316223,36.8690476" id="Line"></path>
            <path d="M0.419998734,32.3452381 L4.70316223,32.3452381" id="Line"></path>
            <path d="M0.419998734,27.8214286 L4.70316223,27.8214286" id="Line"></path>
            <path d="M0.419998734,23.297619 L2.93999114,23.297619" id="Line"></path>
            <path d="M0.419998734,18.7738095 L4.70316223,18.7738095" id="Line"></path>
            <path d="M0.419998734,14.25 L4.70316223,14.25" id="Line"></path>
            <path d="M0.419998734,9.72619048 L4.70316223,9.72619048" id="Line"></path>
            <path d="M0.419998734,5.20238095 L2.93999114,5.20238095" id="Line"></path>
            <path d="M0.419998734,0.678571429 L4.70316223,0.678571429" id="Line"></path>
        </g>
    </g>
</svg>
  </div>
  <p>Education</p>
</a>

</div>

<div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>

<a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

<svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(30.000000, 14.000000)">
        <g id="Group-8" fill="#D98A19">
            <g id="Group-7">
                <g id="Group-6">
                    <path d="M0,0 L0,75.9204805 L69.1511499,75.9204805 L0,0 Z M14.0563973,32.2825679 L42.9457663,63.9991501 L14.2315268,63.9991501 L14.0563973,32.2825679 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
        <g id="Group-20" transform="translate(0.000000, 14.114286)" stroke="#FFFFFF" strokeLinecap="square">
            <path d="M0.419998734,54.9642857 L4.70316223,54.9642857" id="Line"></path>
            <path d="M0.419998734,50.4404762 L4.70316223,50.4404762" id="Line"></path>
            <path d="M0.419998734,45.9166667 L4.70316223,45.9166667" id="Line"></path>
            <path d="M0.419998734,41.3928571 L2.93999114,41.3928571" id="Line"></path>
            <path d="M0.419998734,36.8690476 L4.70316223,36.8690476" id="Line"></path>
            <path d="M0.419998734,32.3452381 L4.70316223,32.3452381" id="Line"></path>
            <path d="M0.419998734,27.8214286 L4.70316223,27.8214286" id="Line"></path>
            <path d="M0.419998734,23.297619 L2.93999114,23.297619" id="Line"></path>
            <path d="M0.419998734,18.7738095 L4.70316223,18.7738095" id="Line"></path>
            <path d="M0.419998734,14.25 L4.70316223,14.25" id="Line"></path>
            <path d="M0.419998734,9.72619048 L4.70316223,9.72619048" id="Line"></path>
            <path d="M0.419998734,5.20238095 L2.93999114,5.20238095" id="Line"></path>
            <path d="M0.419998734,0.678571429 L4.70316223,0.678571429" id="Line"></path>
        </g>
    </g>
</svg>
  </div>
  <p>Education</p>
</a>

</div>

</div>



     
    </div>

    <div className='container' style={{marginTop:'20px', marginBottom:'50px'}}>
      <div className='row'>


      <div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>
        
      <a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

  <svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <image href="https://mensaindia.org/new/assets/img/member/sig/paint.svg" width="71" height="76" x="30" y="20" />
</svg>

  </div>
  <p>MI Art and Design</p>
</a>

</div>

<div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>

<a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

<svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(30.000000, 14.000000)">
        <g id="Group-8" fill="#D98A19">
            <g id="Group-7">
                <g id="Group-6">
                    <path d="M0,0 L0,75.9204805 L69.1511499,75.9204805 L0,0 Z M14.0563973,32.2825679 L42.9457663,63.9991501 L14.2315268,63.9991501 L14.0563973,32.2825679 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
        <g id="Group-20" transform="translate(0.000000, 14.114286)" stroke="#FFFFFF" strokeLinecap="square">
            <path d="M0.419998734,54.9642857 L4.70316223,54.9642857" id="Line"></path>
            <path d="M0.419998734,50.4404762 L4.70316223,50.4404762" id="Line"></path>
            <path d="M0.419998734,45.9166667 L4.70316223,45.9166667" id="Line"></path>
            <path d="M0.419998734,41.3928571 L2.93999114,41.3928571" id="Line"></path>
            <path d="M0.419998734,36.8690476 L4.70316223,36.8690476" id="Line"></path>
            <path d="M0.419998734,32.3452381 L4.70316223,32.3452381" id="Line"></path>
            <path d="M0.419998734,27.8214286 L4.70316223,27.8214286" id="Line"></path>
            <path d="M0.419998734,23.297619 L2.93999114,23.297619" id="Line"></path>
            <path d="M0.419998734,18.7738095 L4.70316223,18.7738095" id="Line"></path>
            <path d="M0.419998734,14.25 L4.70316223,14.25" id="Line"></path>
            <path d="M0.419998734,9.72619048 L4.70316223,9.72619048" id="Line"></path>
            <path d="M0.419998734,5.20238095 L2.93999114,5.20238095" id="Line"></path>
            <path d="M0.419998734,0.678571429 L4.70316223,0.678571429" id="Line"></path>
        </g>
    </g>
</svg>
  </div>
  <p>Education</p>
</a>

</div>

<div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>

<a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

<svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(30.000000, 14.000000)">
        <g id="Group-8" fill="#D98A19">
            <g id="Group-7">
                <g id="Group-6">
                    <path d="M0,0 L0,75.9204805 L69.1511499,75.9204805 L0,0 Z M14.0563973,32.2825679 L42.9457663,63.9991501 L14.2315268,63.9991501 L14.0563973,32.2825679 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
        <g id="Group-20" transform="translate(0.000000, 14.114286)" stroke="#FFFFFF" strokeLinecap="square">
            <path d="M0.419998734,54.9642857 L4.70316223,54.9642857" id="Line"></path>
            <path d="M0.419998734,50.4404762 L4.70316223,50.4404762" id="Line"></path>
            <path d="M0.419998734,45.9166667 L4.70316223,45.9166667" id="Line"></path>
            <path d="M0.419998734,41.3928571 L2.93999114,41.3928571" id="Line"></path>
            <path d="M0.419998734,36.8690476 L4.70316223,36.8690476" id="Line"></path>
            <path d="M0.419998734,32.3452381 L4.70316223,32.3452381" id="Line"></path>
            <path d="M0.419998734,27.8214286 L4.70316223,27.8214286" id="Line"></path>
            <path d="M0.419998734,23.297619 L2.93999114,23.297619" id="Line"></path>
            <path d="M0.419998734,18.7738095 L4.70316223,18.7738095" id="Line"></path>
            <path d="M0.419998734,14.25 L4.70316223,14.25" id="Line"></path>
            <path d="M0.419998734,9.72619048 L4.70316223,9.72619048" id="Line"></path>
            <path d="M0.419998734,5.20238095 L2.93999114,5.20238095" id="Line"></path>
            <path d="M0.419998734,0.678571429 L4.70316223,0.678571429" id="Line"></path>
        </g>
    </g>
</svg>
  </div>
  <p>Education</p>
</a>

</div>

<div className='col-md-3' style={{justifyContent:'center', display:'flex'}}>

<a href="#" className="cardss education">
     <div className="overlay"></div>
  <div className="circle">

<svg width="71px" height="76px" viewBox="29 14 71 76" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(30.000000, 14.000000)">
        <g id="Group-8" fill="#D98A19">
            <g id="Group-7">
                <g id="Group-6">
                    <path d="M0,0 L0,75.9204805 L69.1511499,75.9204805 L0,0 Z M14.0563973,32.2825679 L42.9457663,63.9991501 L14.2315268,63.9991501 L14.0563973,32.2825679 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
        <g id="Group-20" transform="translate(0.000000, 14.114286)" stroke="#FFFFFF" strokeLinecap="square">
            <path d="M0.419998734,54.9642857 L4.70316223,54.9642857" id="Line"></path>
            <path d="M0.419998734,50.4404762 L4.70316223,50.4404762" id="Line"></path>
            <path d="M0.419998734,45.9166667 L4.70316223,45.9166667" id="Line"></path>
            <path d="M0.419998734,41.3928571 L2.93999114,41.3928571" id="Line"></path>
            <path d="M0.419998734,36.8690476 L4.70316223,36.8690476" id="Line"></path>
            <path d="M0.419998734,32.3452381 L4.70316223,32.3452381" id="Line"></path>
            <path d="M0.419998734,27.8214286 L4.70316223,27.8214286" id="Line"></path>
            <path d="M0.419998734,23.297619 L2.93999114,23.297619" id="Line"></path>
            <path d="M0.419998734,18.7738095 L4.70316223,18.7738095" id="Line"></path>
            <path d="M0.419998734,14.25 L4.70316223,14.25" id="Line"></path>
            <path d="M0.419998734,9.72619048 L4.70316223,9.72619048" id="Line"></path>
            <path d="M0.419998734,5.20238095 L2.93999114,5.20238095" id="Line"></path>
            <path d="M0.419998734,0.678571429 L4.70316223,0.678571429" id="Line"></path>
        </g>
    </g>
</svg>
  </div>
  <p>Education</p>
</a>

</div>

</div>



     
    </div>


    </div>

   

   



    </div>
        </div>
      </div>
    </div>



    </div>



    </div>
  );
};

  

 


export default Chapter;
