import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/css/JoinUs.css';


function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-prev" onClick={onClick}>
      Previous
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-next" onClick={onClick}>
      Next
    </button>
  );
}

const JoinUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
<div className='bacimage'>
    <section className='banner'>
      <Slider {...settings}>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 1" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner3.jpg" alt="Banner 2" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 3" />
        </div>
      </Slider>
    </section>


    <div className="event" style={{marginTop:'20px'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <nav className="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s"
               style={{ visibility: 'visible', animationName: 'fadeInDown' }}>

            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active show" id="nav-home-tab" data-toggle="tab" href="#one" role="tab"
                 aria-selected="true">
                <div className="nav-content">
                  <strong>Why Join MENSA?</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#two" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>Book Your Test</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#three" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>Prior Evidence</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-contact-tab2" data-toggle="tab" href="#four" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>Joining FAQ</strong>
                  
                </div>
              </a>
            </div>

          </nav>
          </div>
          </div>

          <div className="row">
          <div className="col-lg-12 col-md-12">
          <div className="tab-content wow fadeInDown animated"
      data-animation="fadeInDown animated" data-delay=".2s" id="nav-tabContent"
      style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
      <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="nav-home-tab">
        <div className="row mb-30">
          <div className="col-lg-12">
            <h1>Why Should I Join Mensa?</h1>
            <p>There are many intelligent reasons to join Mensa. Whether you actively attend Mensa meetings or simply relish the intellectual stimulation that membership promotes, you’ll find the benefits of Mensa membership to be numerous indeed.</p>
            <hr></hr>
            <h1>Does Mensa Provide Intellectual Stimulation?</h1>
            <p>Mensa provides intriguing ways to flex your mental muscles. You’ll find intellectual resources in the national MInd magazines, in local email / whatsapp groups, and at local, national and international conventions.

Whatever your passion, there’s almost certain to be a Special Interest Group (SIG) filled with other Mensans who share it! Mensa world-wide offers approximately 200 SIGs, in mind-boggling profusion from African Violets to zoology. Along the way you’ll find microbiology, and systems analysis, but you’ll also find Sherlock Holmes, chocolate and Star Trek. There are the expected: biochemistry, space science, economics — and the unexpected: poker, roller-skating, scuba diving, UFOs and witchcraft. There are SIGs for bread making, winemaking, cartooning, silversmithing, and clowning. Heraldry, semantics and Egyptology co-exist with beekeeping, motorcycling and tap dancing. Sports SIGs cover the classics (baseball, basketball, and football) and the not-so-classic (skeet shooting, hang gliding, skydiving). And any Mensan who can’t find a SIG to join can easily start one.</p>
<hr></hr>
            <h1>Does Mensa Provide Social Interaction?</h1>
            <p>Mensa meetings are anything but dull! Local groups meet at least monthly. Often it’s for breakfast, lunch or dinner or for get-togethers featuring a speaker or a lively, freewheeling discussion. All are with fellow members who share your intellectual interests. Of course, participation in local group activities is always entirely at your option</p>
            <hr></hr>
            <h1>What Publications Come With Mensa Membership?</h1>
            <p>You will receive the national magazine of Mensa India, MInd magazine with contributions by Mensans on a wide variety of subjects. This will feature the “Mensa World Journal,” an interesting and thought-provoking section which contains views and information about Mensa around the world.

“Isolated M” is a popular and informative newsletter sent to those members who are geographically or otherwise isolated.

Publication information is also available for specific other National Groups.</p>
<hr></hr>
            <h1>What Other Benefits Does Mensa Offer across the World?</h1>
            <p>Many unique programs exist and they vary from country to country.There is also a program that aids travelling Mensans called SIGHT.</p>
            <hr></hr>
            <h1>Does Mensa Help the Community?</h1>
            <p>Mensa members find opportunities to contribute to the betterment of society through volunteer activities within their communities. Do look up two of our programs Dhruv, The Underprivileged Gifted Child Identification and Nurturing Program and the Tribal Mensa Nurturing Program.</p>
            <hr></hr>



            <main class="container">
  <h2 class="">How to Apply ?</h2>
  <div class="card-wrapper">
    <div class="card card-discover" style={{height:'250px'}}>
      <div class="card-icon-title-wrapper">
        <div class="card__icon">
          <div class="icon__wrapper"><i class="fas fa-7x fa-rocket icon__discover"></i></div>
        </div>
        <p class="card__title">Entrance Test</p>
      </div>
      <p class="card__description">
      
      </p>
    </div>
    <div class="card card-empathize" style={{height:'250px'}}>
      <div class="card-icon-title-wrapper" >
        <div class="card__icon">
          <div class="icon__wrapper"><i class="fas fa-7x fa-hand-holding-heart icon__empathize"></i></div>
        </div>
        <p class="card__title">Application Form</p>
      </div>
      <p class="card__description">
        <ul>
          <li> Provide Documents</li>
          <li>Pay Membership Fees</li>
        </ul>
     
      
      </p>
    </div>
    <div class="card card-prototype" style={{height:'250px'}}>
      <div class="card-icon-title-wrapper">
        <div class="card__icon">
          <div class="icon__wrapper"><i class="fas fa-7x fa-pencil-ruler icon__prototype"></i></div>
        </div>
        <p class="card__title">Get qualified in Percentile</p>
      </div>
      <p class="card__description">
       
      </p>
    </div>
  </div>
</main>
          </div>
        </div>
      </div>

      <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div className="row">
    <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
      <div className="card service-wrapper rounded border-0 shadow p-4" style={{height:'340px'}}>
        <div className="icon text-center text-custom h1 shadow rounded bg-white">
          <span className="uim-svg" style={{ display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
              <rect width="20" height="15" x="2" y="3" className="uim-tertiary" rx="3"></rect>
              <path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"></path>
            </svg>
          </span>
        </div>
        <div className="content mt-4">
          <h5 className="title">Mensa Mumbai Test</h5>
          <br></br>
          <h6 className="title">Sunday, 19th May, 2024</h6>
          <p className="text-muted mt-3 mb-0"><b>Special Membership Drive</b> <br></br>
Mathuradas Mill Compound, Lower Parel, Mumbai – 400 013.</p>
          <div className="mt-3">
            <a href="javascript:void(0)" className="text-custom">Read More <i className="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
        <div className="big-icon h1 text-custom">
          <span className="uim-svg" style={{ display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
              <rect width="20" height="15" x="2" y="3" className="uim-tertiary" rx="3"></rect>
              <path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
    </div>

    <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="nav-contact-tab">
    <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="ibox-content m-b-sm border-bottom">
            <div className="p-xs">
              <div className="pull-left m-r-md">
                <i className="fa fa-globe text-navy mid-icon"></i>
              </div>
              <h2>Welcome to our forum</h2>
              <span>Feel free to choose topic you're interested in.</span>
            </div>
          </div>
          <div className="ibox-content forum-container">
            <div className="forum-title">
              <div className="pull-right forum-desc">
                <samll>Total posts: 320,800</samll>
              </div>
              <h3>General subjects</h3>
            </div>
            <div className="forum-item active">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-shield"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">General Discussion</a>
                  <div className="forum-sub-title">Talk about sports, entertainment, music, movies, your favorite color,
                    talk about enything.</div>
                </div>
                <div className="col-md-2 forum-info">
                  <span className="views-number">
                  Raven, J.C.
                  </span>
                  <div>
                    <small>Sr.Name</small>
                  </div>
                </div>
               
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    140
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum-item">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-bolt"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">Introductions</a>
                  <div className="forum-sub-title">New to the community? Please stop by, say hi and tell us a bit about
                    yourself. </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    890
                  </span>
                  <div>
                    <small>Views</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    120
                  </span>
                  <div>
                    <small>Topics</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    154
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum-item active">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-calendar"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">Announcements</a>
                  <div className="forum-sub-title">This forum features announcements from the community staff. If there is a
                    new post in this forum, please check it out. </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    680
                  </span>
                  <div>
                    <small>Views</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    124
                  </span>
                  <div>
                    <small>Topics</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    61
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum-item">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-star"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">Staff Discussion</a>
                  <div className="forum-sub-title">This forum is for private, staff member only discussions, usually
                    pertaining to the community itself. </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    1450
                  </span>
                  <div>
                    <small>Views</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    652
                  </span>
                  <div>
                    <small>Topics</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    572
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum-title">
              <div className="pull-right forum-desc">
                <samll>Total posts: 17,800,600</samll>
              </div>
              <h3>Other subjects</h3>
            </div>
            <div className="forum-item">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-clock-o"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">Lorem Ipsum is simply dummy text. </a>
                  <div className="forum-sub-title">Various versions have evolved over the years, sometimes by accident,
                    sometimes on purpose passage of Lorem Ipsum (injected humour and the like).</div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    1516
                  </span>
                  <div>
                    <small>Views</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    238
                  </span>
                  <div>
                    <small>Topics</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    180
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum-item">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-bomb"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">There are many variations of passages</a>
                  <div className="forum-sub-title"> If you are going to use a passage of Lorem Ipsum, you need to be sure
                    there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on
                    the . </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    1766
                  </span>
                  <div>
                    <small>Views</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    321
                  </span>
                  <div>
                    <small>Topics</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    42
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum-item">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-bookmark"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">The standard chunk of Lorem Ipsum</a>
                  <div className="forum-sub-title">Ipsum generators on the Internet tend to repeat predefined chunks as
                    necessary, making this the first true generator on the Internet.</div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    765
                  </span>
                  <div>
                    <small>Views</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    90
                  </span>
                  <div>
                    <small>Topics</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    11
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="forum-item">
              <div className="row">
                <div className="col-md-9">
                  <div className="forum-icon">
                    <i className="fa fa-ambulance"></i>
                  </div>
                  <a href="forum_post.html" className="forum-item-title">Lorem Ipsum, you need to be sure there</a>
                  <div className="forum-sub-title">Internet tend to repeat predefined chunks as necessary, making this the
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    2550
                  </span>
                  <div>
                    <small>Views</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    122
                  </span>
                  <div>
                    <small>Topics</small>
                  </div>
                </div>
                <div className="col-md-1 forum-info">
                  <span className="views-number">
                    92
                  </span>
                  <div>
                    <small>Posts</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>


    <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="nav-contact-tab">
    <div className="faq_area section_padding_130" id="faq">
      <div className="container" style={{marginTop:'-80px'}}>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section_heading text-center wow fadeInUp" data-wow-delay="0.2s">
              <h3><span>Frequently </span> Asked Questions</h3>
              <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
              <div className="line"></div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="accordion faq-accordian" id="faqAccordion">
              {/* Question 1 */}
              <div className="card1 border-0 wow fadeInUp" data-wow-delay="0.2s" >
                <div className="card-header" id="headingOne" >
                  <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
                    How can I install this app?<span className="fa fa-plus-square" style={{right:'30px', position:'absolute',top:'35px' }}></span>
                  </h6>
                </div>
                <div className="collapse" id="collapseOne" aria-labelledby="headingOne" data-parent="#faqAccordion">
                  <div className="card-body">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi
                      sapiente vitae suscipit.</p>
                    <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
                  </div>
                </div>
              </div>

              {/* Question 2 */}
              <div className="card1 border-0 wow fadeInUp" data-wow-delay="0.3s" >
                <div className="card-header" id="headingTwo">
                  <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    The apps isn't installing?<span className="fa fa-plus-square" style={{right:'30px', position:'absolute',top:'35px' }}></span>
                  </h6>
                </div>
                <div className="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#faqAccordion">
                  <div className="card-body">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi
                      sapiente vitae suscipit.</p>
                    <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
                  </div>
                </div>
              </div>

              {/* Question 3 */}
              <div className="card1 border-0 wow fadeInUp" data-wow-delay="0.4s">
                <div className="card-header" id="headingThree">
                  <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseThree"
                    aria-expanded="true" aria-controls="collapseThree">
                    Contact form isn't working?<span className="fa fa-plus-square" style={{right:'30px', position:'absolute',top:'35px' }}></span>
                  </h6>
                </div>
                <div className="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#faqAccordion">
                  <div className="card-body">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi
                      sapiente vitae suscipit.</p>
                    <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Support Button */}
            <div className="support-button text-center d-flex align-items-center justify-content-center mt-4 wow fadeInUp"
              data-wow-delay="0.5s">
              <i className="fa fa-circle"></i>
              <p className="mb-0 px-2">Can't find your answers?</p>
              <a href="#"> Contact us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>


    </div>
        </div>
      </div>
    </div>



    </div>



    </div>
  );
};

  

 


export default JoinUs;
