import React from 'react';

function Footer() {
  return (
    <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-2 col-md-6">
            <div className="footer-info">
             
             <img src='assets/img/logo/mensatrans2.png' alt='' style={{width:'80%', borderRadius:'20px'}}>
             </img>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>About Us</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Lets Take IO Test</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Mensa International</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Mensa India History</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Member statistics</a></li>
             
            </ul>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Join Us</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Why Join Mensa ?</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Book Your Test</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Prior Evidence</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Rejoin Mensa </a></li> 
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Contact Us</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Contact Details</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Sight</a></li>
              
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>"Newsletter Subscription Form"
              <br></br>
            Write to us : mensahq@mensaindia.org
            </p>
            <form action="" method="post">
              <input type="email" name="email"></input><input type="submit" value="Subscribe"></input></form>
            <div className="social-links mt-3">
                <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
              </div>
              
            

          </div>

        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span>Mensa</span></strong>. All Rights Reserved. Designed by <a href="https://csismtechnologies.com/">CSism Technologies</a>
      </div>
      <div className="credits">
        
        
      </div>
    </div>
  </footer>
  );
}

export default Footer;
