import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


function Home() {

    const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust autoplay speed as needed
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };


  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="slick-prev" onClick={onClick}>
        Previous
      </button>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <button className="slick-next" onClick={onClick}>
        Next
      </button>
    );
  }

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  // useEffect(() => {
  //   const restaurantContainer = document.querySelector(".card-slider");
  //   const leftRButton = document.querySelector(".restaurant-arrow-left");
  //   const rightRButton = document.querySelector(".restaurant-arrow-right");

  //   function updateButtonState() {
  //     leftRButton.disabled = restaurantContainer.scrollLeft <= 0;
  //     rightRButton.disabled =
  //       restaurantContainer.scrollLeft + restaurantContainer.offsetWidth >=
  //       restaurantContainer.scrollWidth;
  //   }

  //   leftRButton.onclick = function () {
  //     restaurantContainer.scrollBy({
  //       left: -restaurantContainer.offsetWidth / 2,
  //       behavior: "smooth",
  //     });
  //   };

  //   rightRButton.onclick = function () {
  //     restaurantContainer.scrollBy({
  //       left: restaurantContainer.offsetWidth / 2,
  //       behavior: "smooth",
  //     });
  //   };

  //   restaurantContainer.addEventListener("scroll", updateButtonState);
  //   updateButtonState();

  //   // Clean up function to remove event listener
  //   return () => {
  //     restaurantContainer.removeEventListener("scroll", updateButtonState);
  //   };
  // }, []); 

  
  
  return (

    <div>


      <section className='banner'>
            <Slider {...settings}>
          <div>
            <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" />
          </div>
          <div>
            <img src="https://mensaindia.org/new/assets/img/herobanner3.jpg"/>
          </div>
          <div>
            <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" />
          </div>
        </Slider>
      </section>
    
  <main id="main">
 
  <section id="features" className="features">
      <div className="container">

        <div className="row">
          <div className="col-md-6  ">
           <div className="row">

                    <div className="col-md-6 newrow">
                    <div className="counter">
                        <span className="counter-value">145,000 +</span>
                        <h3>Total Members</h3>
                    </div>
                  </div>

                    <div className="col-md-6 newrow">
                        <div className="counter2 blue">
                            <span className="counter-value">100 +</span>
                            <h3>Countries</h3>
                        </div>
                    </div>
                    <div className="col-md-6 newrow">
                        <div className="counter3 blue">
                            <span className="counter-value">3,881 +</span>
                            <h3>Members in India</h3>
                        </div>
                    </div>
                    <div className="col-md-6 newrow">
                        <div className="counter0 blue">
                            <span className="counter-value">100,000 +</span>
                            <h3>IQ Test</h3>
                        </div>
                </div>
        </div>
        </div>
        
          <div className="col-md-6 col-lg-6 order-1 order-lg-2" data-aos="zoom-in">
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <figure>
                  <h1>About Us:</h1>
                <p id="typed-text"><strong>"IndSI Membership: 3881+"</strong>
Mensa, an international high-IQ society, boasts a diverse membership with over 14555 individuals in India and more than 100 countries globally. Founded in 1946, Mensa aims to provide a forum for intellectual exchange among its members, who have demonstrated exceptional intelligence through standardized IQ tests.</p>

<h1>IQ:</h1>

<p  id="typed-text"> The organization offers a unique platform for individuals with high IQ scores, fostering a sense of community and enabling networking among like-minded individuals. Mensa conducts regular IQ tests to identify and admit members, ensuring a stimulating environment for those with exceptional cognitive abilities.


</p>
                <p id="typed-text1"></p>
                </figure>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>

    <section id="services" className="services section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Joining Procedures</h2>
          <p></p>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
            <div className="icon-box icon-box-pink">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4 className="title"><a href="">Online Mensa Pre-Test</a></h4>
              <p className="description"  style={{height:'120px'}}>The Pre Test helps you understand if you are Mensa material from the comfort of your home.</p>
              <button className="button-17" role="button">Pre-Test</button>
            </div>
           
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box icon-box-cyan">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4 className="title"><a href="">Official Mensa Test</a></h4>
              <p className="description" style={{height:'120px'}}>The Official Mensa India Test is conducted across locations. Click below to apply</p>
              <button className="button-17 b17" role="button">Apply Now</button>
            </div>
           
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box icon-box-green">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4 className="title"><a href="">Prior Evidence</a></h4>
              <p className="description" style={{height:'120px'}}>Mensa India has recently started accepting prior evidence based on approved standard intelligence tests.</p>
              <button className="button-17 b18" role="button">Know More</button>  
            </div>
            
          </div>

         

        </div>

      </div>
    </section>

    <section id="about" className="about">
    <div className="container">

      <div className="row">
        <div className="col-lg-6" data-aos="zoom-in">
          <img src="assets/img/about.jpg" className="img-fluid" alt="" style={{borderRadius:'20px 20px 20px 130px'}}></img>
        </div>
        <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
          <div className="content pt-4 pt-lg-0">
            <h3>What is IQ Challenge </h3>
            <p className="font-italic">
            "Mind Mastery: The IQ Challenge"
            </p>
            <ul>
              <li style={{textAlign:'justify'}}><i className="icofont-check-circled"></i>  "Unlocking Potential"
Embark on a journey of self-discovery as you delve into mind-bending puzzles and logical challenges, revealing the untapped depths of your intellectual prowess.</li>
              <li style={{textAlign:'justify'}}><i className="icofont-check-circled"></i>"Strategic Thinking"
Navigate through intricate scenarios that demand strategic thinking and quick decision-making, honing your ability to tackle complex problems with precision.</li>
              <li style={{textAlign:'justify'}}><i className="icofont-check-circled"></i>"The Genius Within"
Discover the genius within as the IQ Challenge concludes, offering insights into your cognitive strengths and providing a platform for continuous intellectual growth.</li>
            </ul>
            <p>
            
            </p>
          </div>
        </div>
      </div>

    </div>
  </section>



  <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Success Stories</h2>
          <p></p>
        </div>

        <div className="row">

        <Slider {...settings3}>

          <div className="">
            <div className="member" data-aos="zoom-in">
              <div className="pic"><img src="assets/img/team/team-1.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Chief Executive Officer</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="200">
              <div className="pic"><img src="assets/img/team/team-3.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>CTO</span>
                 
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          </Slider>

        </div>

      </div>
    </section>


  



    {/* <section id="portfolio" className="portfolio">
      <div className="container">
      
        <div className="section-title" data-aos="fade-up">
          <h2>New & Events</h2>
          <p>"Elevate your mind with Mensa's IQ growth platform! Dive into challenging tests, cultivate intelligence, and join us for dynamic events that celebrate intellect and foster personal growth."</p>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">All</li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt=""></img>
              <div className="portfolio-info">
                <h4>Mensa India </h4>
                <p>Read More</p>
              </div>
              <div className="portfolio-links">
                <a href="assets/img/portfolio/portfolio-1.jpg" data-gall="portfolioGallery" className="venobox" title="App 1"><i className="bx bx-plus"></i></a>
                <a href="assets/img/portfolio/portfolio-2.jpg" title="More Details"><i className="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt=""></img>
              <div className="portfolio-info">
                <h4>Web 3</h4>
                <p>Web</p>
              </div>
              <div className="portfolio-links">
                <a href="assets/img/portfolio/portfolio-2.jpg" data-gall="portfolioGallery" className="venobox" title="Web 3"><i className="bx bx-plus"></i></a>
                <a href="" title="More Details"><i className="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-3.jpg" className="img-fluid" alt=""></img>
              <div className="portfolio-info">
                <h4>App 2</h4>
                <p>App</p>
              </div>
              <div className="portfolio-links">
                <a href="assets/img/portfolio/portfolio-3.jpg" data-gall="portfolioGallery" className="venobox" title="App 2"><i className="bx bx-plus"></i></a>
                <a href="" title="More Details"><i className="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-4.jpg" className="img-fluid" alt=""></img>
              <div className="portfolio-info">
                <h4>Card 2</h4>
                <p>Card</p>
              </div>
              <div className="portfolio-links">
                <a href="assets/img/portfolio/portfolio-4.jpg" data-gall="portfolioGallery" className="venobox" title="Card 2"><i className="bx bx-plus"></i></a>
                <a href="" title="More Details"><i className="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          
        </div>

      </div>
    </section> */}

    


    {/* <section id="cta" className="cta">
      <div className="container">

        <div className="row" data-aos="zoom-in">
          <div className="col-lg-9 text-center text-lg-left">
            <h6>Call To Action</h6>
            <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
          <div className="col-lg-3 cta-btn-container text-center">
            <a className="cta-btn align-middle" href="#">Call To Action</a>
          </div>
        </div>

      </div>
    </section> */}

    
    {/* <section id="testimonials" className="testimonials">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Success Stories</h2>
          <p>"Overcoming challenges, seizing opportunities, and achieving dreams - these success stories inspire and motivate with resilience, determination, and triumph."</p>
        </div>
        <div className="owl-carousel testimonials-carousel" data-aos="fade-up" data-aos-delay="100">
            <div className="icon-box icon-box-pink" style={{textAlign:'-webkit-center' }}>
            <div className="newimage"><img src="https://mensaindia.org/new/assets/img/trainers/sheetal.jpg" alt="Image"></img></div>

              <h4 className="title"><a href="">Sheetal Harpale</a></h4>
              <p className="description">
                   It's easy to communicate when you are with like minded people...afterall its difficult to find friends with same disorder 😜</p>
            </div>
          
            <div className="icon-box icon-box-pink" style={{textAlign:'-webkit-center' }}>
            <div className="newimage"><img src="https://mensaindia.org/new/assets/img/trainers/sheetal.jpg" alt="Image"></img></div>

              <h4 className="title"><a href="">Sheetal Harpale</a></h4>
              <p className="description">
                   It's easy to communicate when you are with like minded people...afterall its difficult to find friends with same disorder 😜</p>
             
            </div>
           
            <div className="icon-box icon-box-pink" style={{textAlign:'-webkit-center' }}>
            <div className="newimage"><img src="https://mensaindia.org/new/assets/img/trainers/sheetal.jpg" alt="Image"></img></div>

              <h4 className="title"><a href="">Sheetal Harpale</a></h4>
              <p className="description">
                   It's easy to communicate when you are with like minded people...afterall its difficult to find friends with same disorder 😜</p>
             
            </div>

        </div>
      </div>
    </section> */}

    

{/* <section id="services" className="services section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Success Stories</h2>
          <p>=========================================================================================</p>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
            <div className="icon-box icon-box-pink">
              <div className="icon"><i className="bx bxl-dribbble"></i></div>
              <h4 className="title"><a href="">Nmae</a></h4>
              <p className="description">===============================================================================</p>
              
            </div>
           
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box icon-box-cyan">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4 className="title"><a href="">Nmae</a></h4>
              <p className="description">===============================================================================</p>
             
            </div>
           
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box icon-box-green">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4 className="title"><a href="">Name</a></h4>
              <p className="description">===============================================================================</p>
              
            </div>
          </div>

        

        </div>

      </div>
    </section> */}

    <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Success Stories</h2>
          <p></p>
        </div>

        <div className="row">

        <Slider {...settings2}>

          <div className="">
            <div className="member" data-aos="zoom-in">
              <div className="pic"><img src="assets/img/team/team-1.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Chief Executive Officer</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="200">
              <div className="pic"><img src="assets/img/team/team-3.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>CTO</span>
                 
              </div>
            </div>
          </div>

          <div className="">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""></img></div>
              <div className="member-info">
                <h4>Name Surname</h4>
                <span>Product Manager</span>
                
              </div>
            </div>
          </div>

          </Slider>

        </div>

      </div>
    </section>



    {/* <section id="clients" className="clients">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Clients</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div className="row no-gutters clients-wrap clearfix wow fadeInUp">

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo" data-aos="zoom-in">
              <img src="assets/img/clients/client-1.png" className="img-fluid" alt=""></img>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo" data-aos="zoom-in" data-aos-delay="100">
              <img src="assets/img/clients/client-2.png" className="img-fluid" alt=""></img>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo" data-aos="zoom-in" data-aos-delay="150">
              <img src="assets/img/clients/client-3.png" className="img-fluid" alt=""></img>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo" data-aos="zoom-in" data-aos-delay="200">
              <img src="assets/img/clients/client-4.png" className="img-fluid" alt=""></img>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo" data-aos="zoom-in" data-aos-delay="250">
              <img src="assets/img/clients/client-5.png" className="img-fluid" alt=""></img>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo" data-aos="zoom-in" data-aos-delay="300">
              <img src="assets/img/clients/client-6.png" className="img-fluid" alt=""></img>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo" data-aos="zoom-in" data-aos-delay="350">
              <img src="assets/img/clients/client-7.png" className="img-fluid" alt=""></img>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="client-logo">
              <img src="assets/img/clients/client-8.png" className="img-fluid" alt=""></img>
            </div>
          </div>

        </div>

      </div>
    </section> */}


    {/* <section id="pricing" className="pricing section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Pricing</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="box" data-aos="zoom-in">
              <h6>Free</h3>
              <h4><sup>$</sup>0<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li className="na">Pharetra massa</li>
                <li className="na">Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div className="box featured" data-aos="zoom-in" data-aos-delay="100">
              <h6>Business</h3>
              <h4><sup>$</sup>19<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li className="na">Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>Developer</h3>
              <h4><sup>$</sup>29<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li>Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box" data-aos="zoom-in" data-aos-delay="300">
              <span className="advanced">Advanced</span>
              <h3>Ultimate</h3>
              <h4><sup>$</sup>49<span> / month</span></h4>
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li>Massa ultricies mi</li>
              </ul>
              <div className="btn-wrap">
                <a href="#" className="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> */}



    {/* <section id="faq" className="faq">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Frequently Asked Questions</h2>
        </div>

        <ul className="faq-list">

          <li data-aos="fade-up">
            <a data-toggle="collapse" className="" href="#faq1">Non consectetur a erat nam at lectus urna duis? <i className="icofont-simple-up"></i></a>
            <div id="faq1" className="collapse show" data-parent=".faq-list">
              <p>
                Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="100">
            <a data-toggle="collapse" href="#faq2" className="collapsed">Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque? <i className="icofont-simple-up"></i></a>
            <div id="faq2" className="collapse" data-parent=".faq-list">
              <p>
                Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="200">
            <a data-toggle="collapse" href="#faq3" className="collapsed">Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi? <i className="icofont-simple-up"></i></a>
            <div id="faq3" className="collapse" data-parent=".faq-list">
              <p>
                Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <a data-toggle="collapse" href="#faq4" className="collapsed">Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla? <i className="icofont-simple-up"></i></a>
            <div id="faq4" className="collapse" data-parent=".faq-list">
              <p>
                Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="400">
            <a data-toggle="collapse" href="#faq5" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="icofont-simple-up"></i></a>
            <div id="faq5" className="collapse" data-parent=".faq-list">
              <p>
                Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="500">
            <a data-toggle="collapse" href="#faq6" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="icofont-simple-up"></i></a>
            <div id="faq6" className="collapse" data-parent=".faq-list">
              <p>
                Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Nibh tellus molestie nunc non blandit massa enim nec.
              </p>
            </div>
          </li>

        </ul>

      </div>
    </section> */}


    <section id="contact" className="contact section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Contact Us</h2>
        </div>

        <div className="row">

          <div className="col-lg-5 d-flex align-items-stretch" data-aos="fade-right">
            <div className="info">
              <div className="address">
                <i className="icofont-google-map"></i>
                <h4>Location:</h4>
                <p>India</p>
              </div>

              <div className="email">
                <i className="icofont-envelope"></i>
                <h4>Email:</h4>
                <p> mensahq@mensaindia.org</p>
              </div>

              <div className="phone">
                <i className="icofont-phone"></i>
                <h4>Call:</h4>
                <p>+91 1234567890</p>
              </div>

              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.322760694227!2d72.82602007466247!3d18.961343055563013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c91fceee827d%3A0xcf09380a83d03fc6!2smensa%20india%20(Mumbai)!5e0!3m2!1sen!2sin!4v1708667018224!5m2!1sen!2sin" frameBorder="0" style={{border:'0', width: '100%', height: '290px',    borderRadius:'20px',}} allowFullScreen></iframe> */}
            </div>

          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-left">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input type="text" name="name" className="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div className="validate"></div>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Phone Number</label>
                  <input type="email" className="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                  <div className="validate"></div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Your Email</label>
                <input type="text" className="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div className="validate"></div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Message</label>
                <textarea className="form-control" name="message" rows="1" data-rule="required" data-msg="Please write something for us"></textarea>
                <div className="validate"></div>
              </div>
              <div className="mb-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
  </main>
  
  </div>
  
  );
}

export default Home;
