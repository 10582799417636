import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';

// import Login from './pages/forms/Login';
import Register from './components/forms/Register';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Newlogin from './components/forms/Newlogin';
import JoinUs from './pages/JoinUs';
import Spotlight from './pages/Spolight';
import Chapter from './pages/Chapter';
import Profile from './pages/Profile';
import PollQ from './pages/PollQ';



function App() {
  return (
    <Router>
       
      <div>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/Login" element={<Login />} /> */}
          <Route path="/Register" element={<Register />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Newlogin" element={<Newlogin />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Spotlight" element={<Spotlight />} />
          <Route path="/JoinUs" element={<JoinUs />} />
          <Route path="/Chapter" element={<Chapter />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/PollQ" element={<PollQ />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

// function App() {
//   return (
//     <>
//  <div className="container my-5">
//  <div className="row text-center">
//   <div className="col-6">

//   <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
//   <ol className="carousel-indicators">
//     <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
//     <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
//     <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
//   </ol>
//   <div className="carousel-inner">
//     <div className="carousel-item active">
//       <img className="d-block w-100" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbR4n6lzmBoQRRGCjICBBU7g5m4FSl_rniwg&usqp=CAU" alt="First slide"/>
//     </div>
    
//   </div>
//   <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
//     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span className="sr-only">Previous</span>
//   </a>
//   <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
//     <span className="carousel-control-next-icon" aria-hidden="true"></span>
//     <span className="sr-only">Next</span>
//   </a>
// </div>

  

//   </div>
// </div>
//  </div>


//     </>
//   );
// }



export default App;
