import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/css/AboutUs.css';



function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-prev" onClick={onClick}>
      Previous
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-next" onClick={onClick}>
      Next
    </button>
  );
}

const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const styles = {
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      fontFamily: 'Arial, sans-serif',
      borderRadius: '8px', 
    },
    tableHeader: {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'left',
      backgroundColor: 'rgb(0 12 128)',
      color: 'white', 
    },
    tableData: {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'left',
      color: '#666', 
      borderRadius: '8px', 
    },
    indiaIcon: {
      color: '#ff4500', // India icon color
    },
    usaIcon: {
      color: '#3b5998', // USA icon color
    },
    globeIcon: {
      color: '#008080', // Globe icon color
    },
  };
  



  return (
<div className='bacimage'>
    <section className='banner'>
      <Slider {...settings}>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 1" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner3.jpg" alt="Banner 2" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 3" />
        </div>
      </Slider>
    </section>


    <div className="event" style={{marginTop:'20px'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <nav className="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s"
               style={{ visibility: 'visible', animationName: 'fadeInDown' }}>

            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active show" id="nav-home-tab" data-toggle="tab" href="#one" role="tab"
                 aria-selected="true">
                <div className="nav-content">
                  <strong>Let's Talk IQ</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#two" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>Mensa International</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#three" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>Mensa India History</strong>
                 
                </div>
              </a>
              <a className="nav-item nav-link" id="nav-contact-tab2" data-toggle="tab" href="#four" role="tab"
                 aria-selected="false">
                <div className="nav-content">
                  <strong>Mensa Statistics</strong>
                  
                </div>
              </a>
            </div>

          </nav>
          </div>
          </div>

          <div className="row">
          <div className="col-lg-12 col-md-12">
          <div className="tab-content wow fadeInDown animated"
      data-animation="fadeInDown animated" data-delay=".2s" id="nav-tabContent"
      style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
      <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="nav-home-tab" style={{marginBottom:'50px'}}>
      <div className="row">
    <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2">
      <div className="card service-wrapper rounded border-0 shadow p-4" style={{height:'300px'}}>
        <div className="icon text-center text-custom h1 shadow rounded bg-white">
          <span className="uim-svg" style={{ display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
              <rect width="20" height="15" x="2" y="3" className="uim-tertiary" rx="3"></rect>
              <path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"></path>
            </svg>
          </span>
        </div>
        <div className="content mt-4">
          <h5 className="title">What is intelligence?</h5>
          <p className="text-muted mt-3 mb-0">The whole of cognitive or intellectual abilities required to obtain knowledge, and use it to solve problems that have a well described goal and structure.</p>
          
        </div>
       
        <div className="big-icon h1 text-custom">
          <span className="uim-svg" style={{ display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
              <rect width="20" height="15" x="2" y="3" className="uim-tertiary" rx="3"></rect>
              <path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"></path>
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div className="col-lg- col-md-6 col-12 mt-4 pt-2">
      <div className="card service-wrapper rounded border-0 shadow p-4" style={{height:'300px'}}>
        <div className="icon text-center text-custom h1 shadow rounded bg-white">
          <span className="uim-svg" style={{ display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
              <rect width="20" height="15" x="2" y="3" className="uim-tertiary" rx="3"></rect>
              <path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"></path>
            </svg>
          </span>
        </div>
        <div className="content mt-4">
          <h5 className="title">What is IQ?</h5>
          <p className="text-muted mt-3 mb-0">IQ is an acronym for Intelligence Quotient. The IQ is a measurement of your intelligence and is expressed in a number.</p>
         
        </div>
        <div className="big-icon h1 text-custom">
          <span className="uim-svg" style={{ display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
              <rect width="20" height="15" x="2" y="3" className="uim-tertiary" rx="3"></rect>
              <path className="uim-primary" d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"></path>
            </svg>
          </span>
        </div>
      </div>
    </div>



  </div>
  <div className='row'>
  <div className='col-md-12'>
  <ul>
  <li>
    IQ is a type of standard score that indicates how far above, or how far below, his/her peer group an individual stands in mental ability.
  </li>
  <hr></hr>
  <li>
    <span style={{ fontWeight: '700' }}>The peer group score is an IQ of 100;</span> this is obtained by applying the same test to huge numbers of people from all socio-economic strata of society, and taking the average.
  </li>
  <hr></hr>
  <li>
    <span style={{ fontWeight: '700' }}>The term "IQ" was coined by the psychologist William Stern for the German term Intelligenzquotient in 1912.</span> At that time, IQ was represented as a ratio of mental age to chronological age x 100. So, if an individual of 10 years of age has a mental age of 10, the IQ will be 100. However, if the mental age is greater than the chronological age, e.g., 12 rather than 10, the IQ will be 120. Similarly, if a mental age is lower than the chronological age, the IQ will be lower than 100.
  </li>
  <hr></hr>
  <li>
    <span style={{ fontWeight: '700' }}>When current IQ tests were developed, the average score of the norming sample was defined as IQ 100 and standard deviations (SD) up or down are defined as, for example, 16 or 24 IQ points greater or less than 100.</span> Mensa accepts individuals who score in the top 2%, i.e., two SDs or more above the average. This would include those who score at or above 132 (Stanford-Binet) or 148 (Cattell). Mensa accepts many different tests, as long as they have been standardized and normed and are accepted by professional psychologists’ associations.
  </li>
  <hr></hr>
</ul>

  </div>
</div>





    



      </div>

      <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div className='row'>
    <div className='col-md-12'>
    <h1>Mensa India History:</h1>
    <hr style={{width:'35%', color:'blue'}}></hr>
    
      <ul style={{textAlign:'justify'}}>
  
  <li>
    <b>The History of Mensa in India</b> is well documented by this Article by <b>Amit Das</b> in <b>MInd magazine Apr 2002 edition</b>. Excerpt enclosed below:
  </li>
  <hr></hr>
  <li>
    In <b>1963</b>, when I was cramming for my Higher Secondary exams in the following year, I qualified for Mensa through a test sent by post from London. Then Mensa had about 4,000 members worldwide, nearly three-fourths of whom were in Britain. When I wrote to Mensa, the Chairman Victor Serebriakoff himself replied and encouraged me about getting Mensa going in India. Victor, probably <b>THE most important person</b> in the history of Mensa, took a keen interest in the development of Mensa worldwide. The main thrust of International Mensa was then to expand in USA, but in <b>1965</b> we too received permission to conduct supervised testing in Calcutta and to charge much lower rates than prevalent in Britain.
  </li>
  <hr></hr>
  <li>
    From <b>1966</b> we started inserting personal column ads in newspapers and the membership in India grew from 12 then to about 100 in <b>1970</b>, much of which was in Calcutta. From ‘66, regular Mensa meetings were held in Calcutta at residences of some members. Most Mensans were then college and university students and often they were very argumentative. Often it was necessary for the host to produce a dictionary to settle disputes about the meaning of a word.
  </li>
  <hr></hr>
  <li>
    From <b>1966</b>, the then “Indian Mensa” had a cyclostyled newsletter of irregular periodicity that was sent by post. We conducted some opinion surveys and one interesting result was that a majority of the members responding to a survey thought that India should try to <b>reduce the population to 300 million!</b> The percentage of FMs (female Mensans) was always rather low, but some of them were very good hosts (i.e. the snacks they served were superb). We had some very interesting people. Pranab Ghosh, who had a photographic memory and always came first in all exams, was ardent about his hobby of flying kites (i.e. the ones made of paper). Trinanjan Mitra and Raghu went on exchanging witticisms like an unending game of table tennis. He had shortened his original name Raghupati Raghavan because of “that damn song”. Devinder Singh Garewal, whose father was a Brigadier, was ultra-patriotic, and anyone criticising the Indian armed forces could expect a verbal lashing from him. (Trinanjan got a MBA in Brussels and has settled down in Luxembourg. The three others did Ph.D. from USA and Canada and are now established scientists.)
  </li>
  <hr></hr>
  <li>
    From <b>1966</b>, the Calcutta Ms started meeting visiting Mensans from different countries through the SIGHT service of International Mensa. Two Australian FMs were so interested in the problems of Calcutta that we made an appointment with the Ford Foundation experts of C.M.P.O. The town planners gave such an impromptu seminar about the 25-year perspective plan for Calcutta that we Indian Ms also could also learn a lot. A Californian ecophile praised Indian garbage highly for its bio-degradability. A young German M was so concerned about power shortage in India that he kept the fan shut in the room he was staying at the residence of a Mensan host. The number of Ms in Calcutta increased steadily from <b>1963</b> to <b>1970</b>. Most of the active members were students of the same age group. Starting from <b>1970</b> within two years a large majority of them completed their studies in Calcutta. Then suddenly a large number of them moved out of the city for higher education or for employment. I remained in Calcutta but had to disengage from organising Mensa by the pressure of M.Tech study and the subsequent research fellowship.
  </li>
  <hr></hr>
  <li>
    Just when Mensa was in a poor shape in Calcutta, in <b>1973</b> we heard that Dr. V.V.Pendse of Pune had met Mensa International officials in Europe and wished to play an active role in organising Mensa in India. In September that year, two Mensans, Prabir Mukhopadhyay (working in Bombay after studies in Calcutta) and myself visited Pune and met Dr. Pendse at Jnana Prabodhini, the school for intelligent children. The school had impressive facilities by Indian standards and he offered to use them to set up a permanent headquarters of Indian Mensa with the ready availability of qualified psychologists. Then “Mensa India” was launched in Pune shortly afterwards and the headquarters of Mensa India are still there. There were a number of problems that held up the growth of Mensa in India. But, to understand the prospects, let us see how the number of Mensans in India have grown as compared to USA and the whole world :
  </li>
  <hr></hr>
</ul>


<table style={styles.table}>
      <thead>
        <tr>
        <th style={styles.tableHeader}><i className="fas fa-calendar-alt"></i> Year</th>
          <th style={styles.tableHeader}><i className="fas fa-flag" style={styles.indiaIcon}></i> India</th>
          <th style={styles.tableHeader}><i className="fas fa-flag-usa" style={styles.usaIcon}></i> USA</th>
          <th style={styles.tableHeader}><i className="fas fa-globe" style={styles.globeIcon}></i> Whole World</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={styles.tableData}>1964</td>
          <td style={styles.tableData}>04</td>
          <td style={styles.tableData}>800</td>
          <td style={styles.tableData}>4,000</td>
        </tr>
        <tr>
          <td style={styles.tableData}>1970</td>
          <td style={styles.tableData}>100</td>
          <td style={styles.tableData}>13,000</td>
          <td style={styles.tableData}>17,000</td>
        </tr>
        <tr>
          <td style={styles.tableData}>2001</td>
          <td style={styles.tableData}>700</td>
          <td style={styles.tableData}>50,000</td>
          <td style={styles.tableData}>100,000</td>
        </tr>
      </tbody>
    </table>

  <br></br>
    <p>
    If we can organise Mensa properly in India, there is no reason why in the next few years it should not grow here the way it did in USA between 1964 and 1970.
    </p>
     
    </div>
  </div>
    </div>

    <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div className="row mb-30">
        <div className="col-lg-12">
        <div className="container">
      <h3 className="card-title">Mensa History </h3>
      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline3">
            <div className="timeline">
              <div className="timeline-icon"><span className="year">1946</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                An exclusively British club 1946 - 1960, a society for bright people found by, Roland Berrill, a Barrister & Dr. Lance Ware, a Scientist and Lawyer.
                </p>
              </div>
            </div>
            <div className="timeline mgt1" >
              <div className="timeline-icon"><span className="year">1960</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                
The establishment of a branch in America in the fall of 1960 marked a significant milestone in the organization's expansion efforts. 
                </p>
              </div>
            </div>
            <div className="timeline mgt2">
              <div className="timeline-icon"><span className="year">1961</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                In 1961, a referendum was held to determine if a formal constitution should be written. That vote, in August 1961, failed (153 for, 418 against, 3 abstentions).

1961 - 1962, almost all matters continued to be handled through the governing (British) board in London
                </p>
              </div>
            </div>
            <div className="timeline mgt3" >
              <div className="timeline-icon"><span className="year">1963</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                November 1963 Annual General Meeting in London, the membership approved a policy on the structuring of an "international" Mensa, and separation of the international board from the British board.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline3">
            <div className="timeline mgt4">
              <div className="timeline-icon"><span className="year">1964</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                The first “formal” international constitution was approved in June 1964 (2,206 for, 264 against);

In September 1964, the first election was held, with the Wilson/Serebriakoff panel receiving the most votes (by about 4 to 1).

Another provision of the 1964 constitution was the establishment of Mensa as a legal entity.
                </p>
              </div>
            </div>
            <div className="timeline mgt5">
              <div className="timeline-icon"><span className="year">1965</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                Intermensa, Ltd. was incorporated as a “holding company” on May 7, 1965. Maurice Salzedo, a solicitor and active British Mensan, became Secretary of the Corporation.
                </p>
              </div>
            </div>
            <div className="timeline mgt6">
              <div className="timeline-icon"><span className="year">1969</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                In late 1969, a major reshuffling of the International General Committee (IGC) took place, following which several substantial constitutional amendments were proposed, approved and enacted.
                </p>
              </div>
            </div>
            <div className="timeline  mgt7">
              <div className="timeline-icon"><span className="year">1970</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                Although the people and the “job titles” were in seemingly constant flux throughout the 1970’s, the IGC nevertheless remained essentially intact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline3">
            <div className="timeline  mgt8">
              <div className="timeline-icon"><span className="year">1980</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                In 1980, however, the inequities of the U.N.-style system (from the large groups’ perspective) had generated serious problems.
                </p>
              </div>
            </div>
            <div className="timeline  mgt9">
              <div className="timeline-icon"><span className="year">1981</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                Hyman Brock (the Chairman of Mensa Canada at that time) brought the major players together in Miami and a compromise (“The Miami Pact”) was hammered out on October 31, 1981.
                </p>
              </div>
            </div>
            <div className="timeline  mgt10">
              <div className="timeline-icon"><span className="year">1982</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                End of April 1982, Two boards were created: the IGC (International General Council), consisting of the International Chairman and the CEOs of all recognized national Mensas (with some qualifications).

The Society was officially without officers from May 1, 1982 until July 1, 1982, when the newly-elected officials took office.
                </p>
              </div>
            </div>
            <div className="timeline  mgt11">
              <div className="timeline-icon"><span className="year">1985</span></div>
              <div className="timeline-content">
                <h3 className="title"></h3>
                <p className="description">
                In 1976, Mensa International, Ltd., another corporate entity, was incorporated in England. It co-existed with Intermensa, Ltd. from 1976 to 1985.

Intermensa, Ltd. was dissolved in May 1985 in favor of MIL for legal reasons.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul>
      <li><strong>Detailed History</strong></li>
      <li><strong>1946</strong> - Mensa was, for all intents and purposes, an exclusively British club from its beginnings in <strong>1946</strong> up until <strong>1960</strong>. The establishment of a branch in America in the fall of <strong>1960</strong>, with its subsequent rapid growth, brought forth the inevitable issue of co-existing national Mensa groups versus one international group (with many branches). Since Roland Berrill had felt that a formal constitution was unnecessary, Mensa had never had anything but the most rudimentary official structure. As Mensa grew, however, the adequacy of the informal governmental and organizational structure came under fire and discussions began on the adoption of a formal constitution. In <strong>1961</strong>, a referendum was held to determine if a formal constitution should be written. That vote, in August <strong>1961</strong>, failed (153 for, 418 against, 3 abstentions). For the next few years, almost all matters continued to be handled through the governing (British) board in London.</li>
      <li><strong>1963</strong> - At the November <strong>1963</strong> Annual General Meeting in London, the membership approved a policy on the structuring of an “international” Mensa, and separation of the international board from the British board. This policy provided for the drafting and membership approval of a constitution, with subsequent elections to fill the posts created by it. The first “formal” international constitution was approved in June <strong>1964</strong> (2,206 for, 264 against); it provided for 8 officers (Chairman, General Secretary, Treasurer, Membership Officer, Developments Officer, Editorial Committee Chair, Research Committee Chair and Premises Committee Chair). It further provided for one representative from each of the recognized national Mensa groups (United Nations style - without regard to group size). In September <strong>1964</strong>, the first election was held, with the Wilson/Serebriakoff panel receiving the most votes (by about 4 to 1). The “panel,” or “slate” system of voting is still used in International Mensa elections today (although there had not been a contested international election in many years until recently).</li>
      <li><strong>1965</strong> - Another provision of the <strong>1964</strong> constitution was the establishment of Mensa as a legal entity. After considerable work, Intermensa, Ltd. was incorporated as a “holding company” on May 7, <strong>1965</strong>. Maurice Salzedo, a solicitor and active British Mensan, became Secretary of the Corporation. It is interesting to note that in <strong>1965</strong>, the name “Mensa” was already in use in another corporate name and thus the preferred “Mensa International, Ltd.” could not be used.</li>
      <li><strong>1969</strong> - In late <strong>1969</strong>, a major reshuffling of the International General Committee (IGC) took place, following which several substantial constitutional amendments were proposed, approved and enacted. Although the people and the “job titles” were in seemingly constant flux throughout the <strong>1970s</strong>, the IGC nevertheless remained essentially intact.</li>
      <li><strong>1980</strong> - By <strong>1980</strong>, however, the inequities of the U.N.-style system (from the large groups’ perspective) had generated serious problems. The United States, with over 30,000 members (and a large financial obligation to International Mensa), had but one vote, the same as the Ivory Coast, which had only 10 members and virtually no financial obligation. Following a period of contentiousness, and several unsuccessful attempts to create acceptable drafts of a revised constitution, Hyman Brock (the Chairman of Mensa Canada at that time) brought the major players together in Miami and a compromise (“The Miami Pact”) was hammered out on October 31, <strong>1981</strong>.</li>
      <li><strong>1982</strong> - The subsequently-approved constitutional changes and election dramatically altered the composition of the international governing body. Two boards were created: the IGC (International General Council), consisting of the International Chairman and the CEOs of all recognized national Mensas (with some qualifications). This body generally acts in an advisory capacity to the second board, the IBD (International Board of Directors).</li>
      <li><strong>1985</strong> - In <strong>1976</strong>, Mensa International, Ltd., another corporate entity, was incorporated in England. It co-existed with Intermensa, Ltd. from <strong>1976</strong> to <strong>1985</strong>. Intermensa, Ltd. was dissolved in May <strong>1985</strong> in favor of MIL for legal reasons.</li>
      <li><strong>General Note</strong> - Finally, the requirements for voting representation on the IGC/IBD have been modified numerous times throughout the years. In the following sections, no attempt has been made to confirm that the individuals listed were actually entitled to vote at all times during their tenure.</li>
    </ul>
    </div>
        </div>
      </div>
    </div>


    <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div className="row mb-30">
        <div className="col-lg-">
          <h6>Here are some random member statistics about active Mensa members in India. We found it fun to compile these. Hope you do too. Enjoy!
 </h6>
 <img src='https://mensaindia.org/new/assets/img/stat1.jpg' style={{width:'100%'}}></img>
 <h6>Here are some random member statistics about active Mensa members in India. We found it fun to compile these. Hope you do too. Enjoy!
 </h6>
 <img src='https://mensaindia.org/new/assets/img/stat2.jpg' style={{width:'100%'}}></img>
 <h6>Here are some random member statistics about active Mensa members in India. We found it fun to compile these. Hope you do too. Enjoy!
 </h6>
 <img src='https://mensaindia.org/new/assets/img/stat3.jpg' style={{width:'100%'}}></img>

        </div>
      </div>
    </div>













    </div>
        </div>
      </div>
    </div>



    </div>



    </div>
  );
};

  

 


export default AboutUs;
