import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/css/Contact.css';

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-prev" onClick={onClick}>
      Previous
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button className="slick-next" onClick={onClick}>
      Next
    </button>
  );
}

const Contact = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (

<div className='bacimage'>
    <section className='banner'>
      <Slider {...settings}>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 1" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner3.jpg" alt="Banner 2" />
        </div>
        <div>
          <img src="https://mensaindia.org/new/assets/img/herobanner2.jpg" alt="Banner 3" />
        </div>
      </Slider>
    </section>

    <div className="container" style={{ maxWidth: '1200px', margin: 'auto', marginTop: '50px' }}>
      <h2>Contact Us:</h2>
      <p>
      <strong>For Membership Related Queries:
Please contact mensahq@mensaindia.org.
  </strong>
<br></br>For Testing Schedule, Special Interest Groups, Events, SIGHT:
Please contact the Chapter Head of your nearest chapter for testing, SIGs, events and in case you are a visiting Mensan, please contact mensahq@mensaindia.org.
<br></br>
<br></br>
<br></br>


<b>Underprivileged Gifted Child Identification & Nurturing Contact:</b>
Please contact Dr. Narayan Desai (Pune) or Ms Shilpa Kulkarni for the Tribal Mensa Nurturing Program and Mr. Kishore Asthana (Delhi/NCR) for Mensa India’s Underprivileged Gifted Child Identification & Nurturing Program, Project Dhruv. Contact details are given below.

In case your query is not answered within a week, please send an email to the President, Mensa India, along with the original emailed query.
      </p>
      
    </div>

    <div className="container" style={{marginTop:'40px'}}>
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="mb-3">
            <h5 className="card-title" style={{textAlign:"center"}}>IMPORTANT CONTACTS</h5>
          </div>
        </div>
       
      </div>

      <div className="row align-items-center">

      <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
               
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <span className="badge badge-soft-success mb-0" style={{marginLeft:'15px',marginRight:'10px'}}>girish.bapat@jnanaprabodhini.org</span>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'15px'}}>
              
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> <a href='mailto:girish.bapat@jnanaprabodhini.org' style={{color:'white'}}>Contact </a></button>  */}
            
          </div>
         
         
          </div>
        
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Mr. Imtiyaz Saigara</a></h5>
              <span className="badge badge-soft-success mb-0">PRESIDENT, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>
      

 </div>
    </div>


    <div className="container" style={{marginTop:'20px'}}>
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="mb-3">
            <h5 className="card-title"  style={{textAlign:"center"}}>CONTACTS BY LOCATION</h5>
          </div>
        </div>
      
      </div>

      <div className="row align-items-center">

      <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>
      

 </div>
    </div>

    <div className="container" style={{marginTop:'20px'}}>
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="mb-3">
            <h5 className="card-title"  style={{textAlign:"center"}}>CONTACTS BY PROGRAMS</h5>
          </div>
        </div>
       
      </div>

      <div className="row align-items-center">

       <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="dropdown float-end">
           
           
          </div>
          <div className="d-flex align-items-center">
            
            <div className="flex-1 ms-3">
              <h5 className="font-size-16 mb-1"><a href="#" className="text-dark">Dr. Girish Bapat</a></h5>
              <span className="badge badge-soft-success mb-0">CHAIRMAN, MENSA INDIA</span>
            </div>
            <div>
              <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar"
                className="avatar-md rounded-circle img-thumbnail" />
            </div>
          </div>
          <div className="mt-3 pt-1" style={{textAlign:'center'}}>
            <div className="d-flex gap-2 pt-4"  style={{marginLeft:'12px'}}>
            {/* <button type="button" className="btn btn-primary btn-sm w-50"><i className="bx bx-message-square-dots me-1"></i> Contact</button> */}
          </div>
         
          </div>
        
        </div>
      </div>
    </div>
      

      </div>
          </div>

    </div>
  );
};



export default Contact;
