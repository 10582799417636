// src/Register.js
import axios from 'axios';
import React, { useState } from 'react';

const Register = () => {
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
    const response = await axios.post('/register', { username, password });
    if (response && response.data) {
        console.log(response.data); // Assuming your backend sends a message upon successful registration
    }
    } catch (error) {
    console.error('Error registering:', error.response ? error.response.data : error.message);
    }
};

return (
    <div>
    <h2>Register</h2>
    <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Register</button>
    </form>
    </div>
);
};

export default Register;
