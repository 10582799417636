
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const Profile =() => {
    
    Modal.setAppElement('#root'); 

    const [modalIsOpen1, setIsOpen1] = useState(false);
  
    function openModal1() {
      setIsOpen1(true);
    }
  
    function closeModal1() {
      setIsOpen1(false);
    }
  
   
  
    const [selectedOption, setSelectedOption] = useState('');
    const [showResult, setShowResult] = useState(false);
  
    const handleOptionChange = (option) => {
      setSelectedOption(option);
    };
  
    const handleResult = () => {
      setShowResult(true);
    };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };
  
  Modal.setAppElement('#root'); 

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const downloadIDCardAsPDF = () => {
    const idCardElement = document.getElementById('id-card');
    html2canvas(idCardElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 10, 10);
      pdf.save('id-card.pdf');
    });
  };

  return (
<div>
    <div className="container" style={{marginTop:'120px'}}>
        <div className="row flex-lg-nowrap">
            
            <div className="col">
                <div className="row">
                    <div className="">
                        <div className="card" style={{height:'170px'}}>
                            <div className="card-body">
                                <div className="e-profile">
                                    <div className="row">
                                        <div className="col-12 col-sm-auto mb-3">
                                            <div className="mx-auto" style={{width: '140px'}}>
                                                <div className="d-flex justify-content-center align-items-center rounded"
                                                    style={{height: '140px', background: 'rgb(233, 236, 239)'}}>
                                                    <span
                                                        style={{color: 'rgb(166, 168, 170)' }}>140x140</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                                            <div className="text-center text-sm-left mb-2 mb-sm-0">
                                                <h4 className="pt-sm-2 pb-1 mb-0 text-nowrap">John Smith</h4>
                                                <br></br>
                                                <br></br>
                                                
                                                {/* <p className="mb-0">@johnny.s</p> */}
                                                {/* <div className="text-muted"><small>Last seen 2 hours ago</small></div> */}
                                                <div className="mt-2">
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fa fa-fw fa-camera"></i>
                                                        <span>Change Photo</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-center text-sm-right">
                                                <span className="badge badge-secondary">administrator</span>
                                                <div className="text-muted"><small>Joined 09 Dec 2017</small></div>
                                            </div>
                                            <div className="" style={{height:'60px'}}>
                            <div className="card-body">
                                <div className="px-xl-3">
                                    <button className="btn btn-block btn-secondary" >
                                        <i className="fa fa-sign-out"></i>
                                        <span>Logout</span>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                                        </div>
                                    </div>
                                    {/* <ul className="nav nav-tabs ">
                                        <li className="nav-item "><a  className="active nav-link">Settings</a></li>
                                    </ul> */}
                                    <div className="tab-content pt-3 ">
                                        <div className="tab-pane active">
                                            <form className="form">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Full Name</label>
                                                                    <input className="form-control" type="text" name="name"
                                                                        placeholder="John Smith" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Username</label>
                                                                    <input className="form-control" type="text"
                                                                        name="username" placeholder="johnny.s"
                                                                       />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Email</label>
                                                                    <input className="form-control" type="text"
                                                                        placeholder="user@example.com"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col mb-3">
                                                                <div className="form-group">
                                                                    <label>About</label>
                                                                    <textarea className="form-control" rows="5"
                                                                        placeholder="My Bio"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-12 col-sm-6 mb-3">
                                                        <div className="mb-2"><b>Change Password</b></div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Current Password</label>
                                                                    <input className="form-control" type="password"
                                                                        placeholder="••••••"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>New Password</label>
                                                                    <input className="form-control" type="password"
                                                                        placeholder="••••••"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Confirm <span
                                                                            className="d-none d-xl-inline">Password</span></label>
                                                                    <input className="form-control" type="password"
                                                                        placeholder="••••••"/></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-5 offset-sm-1 mb-3">
                                                        <div className="mb-2"><b>Keeping in Touch</b></div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <label>Email Notifications</label>
                                                                <div className="custom-controls-stacked px-2">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="notifications-blog"/>
                                                                        <label className="custom-control-label"
                                                                            >Blog posts</label>
                                                                    </div>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="notifications-news" />
                                                                        <label className="custom-control-label"
                                                                            >Newsletter</label>
                                                                    </div>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="notifications-offers"/>
                                                                        <label className="custom-control-label"
                                                                            >Personal
                                                                            Offers</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className="row">
                                                    <div className="col d-flex justify-content-start">
                                                        <button className="btn btn-primary" type="submit">Save
                                                            Changes</button>
                                                    </div>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mb-3 footernew">
                        {/* <div className="card mb-3" style={{height:'60px'}}>
                            <div className="card-body">
                                <div className="px-xl-3">
                                    <button className="btn btn-block btn-secondary">
                                        <i className="fa fa-sign-out"></i>
                                        <span>Logout</span>
                                    </button>
                                </div>
                            </div>
                        </div> */}
                       
                    </div>
                </div>
            </div>
        </div>


    <div>
      <button onClick={openModal}>Show ID Card</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="ID Card Modal"
      >
         <div id="id-card">
    <div class="id-card material-ui-shadow">

    
      <div class="id-card__mugshot">
        <img src="http://www.travelcontinuously.com/wp-content/uploads/2018/04/empty-avatar.png" id="id-card-mugshot"/>
      </div>
    
      <div class="id-card__logo logo">
        <span class="logo__big">Mensa</span>
        <br></br>
        <span class="logo__small">foundation</span>
      </div>
     
      <div class="id-card__subject-id">

      </div>
     
      <div class="id-card__banner">
        <span class="id-card__banner-text">EXPERIMENT S9.3a</span>
      </div>
      
      <div class="id-card__details">
        <div class="id-card__detail">
          <span class="id-card__detail-label">Name</span>
          <span class="id-card__detail-value" id="id-card-name">Glip-Glop Woodenstein</span>
        </div>
        <div class="id-card__detail">
          <span class="id-card__detail-label">Date of Birth</span>
          <span class="id-card__detail-value" id="id-card-date-of-birth">09/20/1980</span>
        </div>
        <div class="id-card__detail">
          <span class="id-card__detail-label">Issue Date</span>
          <span class="id-card__detail-value">08/01/2018</span>
        </div>
        <div class="id-card__detail">
          <span class="id-card__detail-label">Release Date</span>
          <span class="id-card__detail-value">08/01/2020</span>
        </div>
        <div class="id-card__detail">
          <span class="id-card__detail-label">Gender</span>
          <span class="id-card__detail-value" id="id-card-gender">M</span>
        </div>
      </div>
     
      {/* <div class="id-card__details id-card__details--short">
        <div class="id-card__detail">
          <span class="id-card__detail-label">Gender</span>
          <span class="id-card__detail-value" id="id-card-gender">M</span>
        </div>
        <div class="id-card__detail">
          <span class="id-card__detail-label">Height</span>
          <span class="id-card__detail-value" id="id-card-height">6'03"</span>
        </div>
        <div class="id-card__detail">
          <span class="id-card__detail-label">Weight</span>
          <span class="id-card__detail-value" id="id-card-weight">145</span>
        </div>
      </div>
       */}
 <button onClick={closeModal} style={{ position:'absolute',right:'-10px',top:'-10px',width:'30px',height:'30px',borderRadius:'50%'}}>X</button>
 <button onClick={downloadIDCardAsPDF} style={{position:'absolute', right:'10px',top:'190px'}}>Download</button>
    </div>


  </div>

      
      </Modal>
    </div>


    {/* <div >
      <button onClick={openModal1}>|Poll</button>
     <div className='boxshadow'>
      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={closeModal1}
        style={customStyles}
        contentLabel=""
      >
     
     
      
      <button onClick={handleResult}>Submit</button>
      
      {showResult && (
        <div>
          <p>{selectedOption === 'Paris' ? 'Correct!' : 'Incorrect!'}</p>
        </div>
      )}
      
    </Modal>
    </div>
    </div> */}
    
   
    </div>

    <footer className='' id="footer" 
    
></footer>
    </div>

  );
};


  

 


export default Profile;
