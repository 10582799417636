
import React, { useState } from 'react';

import '../assets/css/PollQ.css';



const PollQ =() => {

  
  

  return (

    <div class="container" style={{top:'125px', position:'relative'}}>
      <h1 id="title" class="header__title" style={{textAlign:'center'}}>Poll Survey Form</h1>
    <p id="description" class="header__description" style={{textAlign:'center'}}>
      We would love to hear your thoughts on how we can improve your experience.
    </p>
      <div className='row'>
      <div className='col-md-6'> 
  <header class="header">
    
    <img src="https://res.cloudinary.com/alexandracaulea/image/upload/v1585333913/illustration_x46ict.svg" alt="" aria-hidden="true" class="illustration" />
  </header>
  </div>
  <div className='col-md-6'>
  <main class="main" style={{top:'-60px', position:'relative'}}>
    <form action="#" method="GET" id="survey-form" class="survey" noValidate>
      {/* <div class="progressbar" tabindex="0" role="progressbar" aria-valuemin="1" aria-valuemax="5" aria-valuenow="1" aria-valuetext="Question 1 of 5: How long have you been using our product?">
        <span class="progressbar__step active" aria-hidden="true"></span>
        <span class="progressbar__step" aria-hidden="true"></span>
        <span class="progressbar__step" aria-hidden="true"></span>
        <span class="progressbar__step" aria-hidden="true"></span>
        <span class="progressbar__step" aria-hidden="true"></span>
      </div> */}


      <section class="survey__panel survey__panel--current" aria-hidden="false" data-index="1" data-panel="firstPanel" data-requirement="option">
        <h2 class="survey__panel__question">
          <span class="visuallyhidden">Question 1 of 5 </span>How long have you been using our product?
        </h2>
        <div class="survey__panel__period">
          <div class="form-group">
            <input id="never" type="radio" name="question_1" value="never" />
            <label for="never">never</label>
          </div>
          <div class="form-group">
            <input id="lessthenoneyear" type="radio" name="question_1" value="lessthenoneyear" />
            <label for="lessthenoneyear">0 - 1 year</label>
          </div>
          <div class="form-group">
            <input id="onetotwoyears" type="radio" name="question_1" value="onetotwoyears" />
            <label for="onetotwoyears">1 - 2 years</label>
          </div>
          <div class="form-group">
            <input id="morethentwoyears" type="radio" name="question_1" value="morethentwoyears" />
            <label for="morethentwoyears"><span class="visuallyhidden">more then</span><span aria-hidden="true"></span> 2 years</label>
          </div>
        </div>
        <p class="error-message"></p>
      </section>

      <section class="survey__panel" aria-hidden="true" data-index="2" data-panel="secondPanel" data-requirement="option">
        <h2 class="survey__panel__question">
          <span class="visuallyhidden">Question 2 of 5 </span>How do rate your overall satisfaction about the product?
        </h2>
        <div class="survey__panel__satisfaction">
          <div class="form-group">
            <input id="notsatified" type="radio" class="radiobox" name="question_2" value="Not Satisfied" />
            <label for="notsatified">Not Satisfied</label>
          </div>
          <div class="form-group">
            <input id="quitesatisfied" type="radio" class="radiobox" name="question_2" value="Quite Satisfied" />
            <label for="quitesatisfied">Quite Satisfied</label>
          </div>
          <div class="form-group">
            <input id="satisfied" type="radio" class="radiobox" name="question_2" value="Satisfied" />
            <label for="satisfied">Satisfied</label>
          </div>
          <div class="form-group">
            <input id="completelysatisfied" type="radio" class="radiobox" name="question_2" value="Completely Satisfied" />
            <label for="completelysatisfied">Completely Satisfied</label>
          </div>
        </div>
        <p class="error-message"></p>
      </section>

      <section class="survey__panel" aria-hidden="true" data-index="3" data-panel="thirdPanel" data-requirement="checkbox">
        <h2 class="survey__panel__question">
          <span class="visuallyhidden">Question 3 of 5 </span>How did you hear about our company?
        </h2>
        <div class="survey__panel__hearabout">
          <div class="form-group">
            <input type="checkbox" class="checkbox" id="searchengine" name="question_3" value="Search Engine" />
            <label for="searchengine">Search Engine</label>
          </div>
          <div class="form-group">
            <input type="checkbox" class="checkbox" id="friend" name="question_3" value="A friend of mine" />
            <label for="friend">A friend of mine</label>
          </div>
          <div class="form-group">
            <input type="checkbox" class="checkbox" id="socialmedia" name="question_3" value="Social Media" />
            <label for="socialmedia">Social Media</label>
          </div>
          <div class="form-group">
            <input type="checkbox" class="checkbox" id="other" name="question_3" value="Other" />
            <label for="other">Other</label>
          </div>
        </div>
        <p class="error-message"></p>
      </section>

      <section class="survey__panel" aria-hidden="true" data-index="4" data-panel="fourthPanel" data-requirement="option">
        <h2 class="survey__panel__question">
          <span class="visuallyhidden">Question 4 of 5 </span>Would you recommend our product to your friends?
        </h2>
        <div class="survey__panel__recommendation">
          <div class="form-group">
            <input type="radio" class="radiobox" name="question_4" id="norecomment" value="no" />
            <label for="norecomment">No</label>
          </div>
          <div class="form-group">
            <input type="radio" class="radiobox" name="question_4" id="mayberecommend" value="maybe" />
            <label for="mayberecommend">Maybe</label>
          </div>
          <div class="form-group">
            <input type="radio" class="radiobox" name="question_4" id="yesrecommend" value="yes" />
            <label for="yesrecommend">Yes</label>
          </div>
          <div class="form-group form-group-textarea">
            <label for="recommendation">Additional message:</label>
            <textarea name="recommendText" id="recommendation" spellcheck="false"></textarea>
          </div>
        </div>
        <p class="error-message"></p>
      </section>

      <section class="survey__panel" aria-hidden="true" data-index="5" data-panel="fifthPanel" data-requirement="name;email;country;age">
        <h2 class="survey__panel__question">
          <span class="visuallyhidden">Question 5 of 5 </span>Please fill your details
        </h2>
        <div class="survey__panel__personaldetails">
          <div class="form-group">
            <label for="name" id="name-label" class="name">Name:</label>
            <input type="text" id="name" name="name" required placeholder="Your Name" />
            <p class="error-message"></p>
          </div>
          <div class="form-group">
            <label for="email" id="email-label" class="email">Email:</label>
            <input type="email" id="email" name="email" required placeholder="Your Email" />
            <p class="error-message"></p>
          </div>
          <div class="form-group">
            <select name="country" id="dropdown" >
              <option value>Your Country</option>
              <option value="Afghanistan">Afghanistan</option>
             
            </select>
            <p class="error-message"></p>
          </div>
          <div class="form-group">
            <label for="number" id="number-label" class="age">Age</label>
            <input type="number" id="number" name="age" min="0" max="150" required placeholder="Age" />
            <p class="error-message"></p>
          </div>
        </div>
      </section>
      <div class="form-buttons" >
        <button class="button" type="button" name="prev" disabled="disabled" aria-label="Previous" aria-hidden="true">
          Prev
        </button>
        <button class="button" type="button" name="next" aria-label="Next" aria-hidden="false">Next</button>
        <button class="button" type="submit" id="submit" name="submit" disabled="disabled" aria-hidden="true">
          Submit
        </button>
      </div>
    </form>
  </main>
  </div>
</div>
<br></br>
<br></br>

<br></br>



</div>


  );
};


  

 


export default PollQ;
