import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React, { useState, useEffect, useRef,navLinkRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


function Header({ username }) {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navLinkRef = useRef(null);

  const handleClick2 = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = dropdownRef.current;
      const navLink = navLinkRef.current;

      if (dropdown && navLink && !dropdown.contains(event.target) && !navLink.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);




  
  const handleClick = () => {
    document.body.classList.remove('mobile-nav-active');
    const overlay = document.querySelector('.mobile-nav-overly');
    if (overlay) {
        overlay.style.display = 'none';

        $('.mobile-nav-toggle i').toggleClass('icofont-close icofont-navigation-menu');
    }
};




  return (
    // <header className="navbar navbar-expand-lg navbar-dark bg-primary">
    //   {/* Logo on the left */}
    //   <Link className="navbar-brand" to="/">
    //     <img src={logo} alt="Logo" style={{ width: '50px', height: 'auto' }} />
    //   </Link>
      
    //   {/* Hamburger menu for small screens */}
    //   <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //     <span className="navbar-toggler-icon"></span>
    //   </button>

    //   {/* Navigation on the right */}
    //   <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //     <ul className="navbar-nav ml-auto">
    //       <li className="nav-item">
    //         <Link to="/" className="nav-link">Home</Link>
    //       </li>
    //       {username ? (
    //         <li className="nav-item">
    //           <span className="nav-link">Welcome, {username}</span>
    //         </li>
    //       ) : (
    //         <li className="nav-item">
    //           <Link to="/login" className="nav-link">Login</Link>
    //         </li>
    //       )}
    //     </ul>
    //   </div>
    // </header>
    <header id="header" className="fixed-top">
    <div className="container d-flex">

 

      <div className="logo mr-auto">
        <h1 className="text-light"><NavLink to="./"><img src="assets/img/logo/mensatrans.png" alt="" className=""></img></NavLink></h1>
       
      
      </div>

      <nav className="nav-menu d-none d-lg-block">
        <ul>
          <li className="active"><NavLink to="./">Home</NavLink></li>
          <li className=""><NavLink to="/AboutUs">About</NavLink>
           
          </li>
          <li className=""><NavLink to="/JoinUs">Join Us</NavLink>
            
          </li>


          <li><NavLink to="/Spotlight">Spotlight</NavLink></li>
          <li><NavLink to="/Chapter">Chapter & SIG</NavLink></li>
          <li><NavLink to="/Contact">Contact</NavLink></li>
          <li>
      <NavLink  onClick={handleClick2} ref={navLinkRef}>
        Members Login <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </NavLink>
      {isOpen && (
        <div className="dropdown-content" ref={dropdownRef}>
          {/* Your dropdown content here */}
          {/* For example: */}
          <NavLink to="/NewLogin" onClick={() => setIsOpen(false)}>Login</NavLink>
          <NavLink to="/profile" onClick={() => setIsOpen(false)}>Profile</NavLink>
          {/* Add more NavLink components for other dropdown items */}
        </div>
      )}
    </li>
  
   
     
         
          
          {/* {username ? (
            <li className="nav-item">
              <span className="nav-link">Welcome, {username}</span>
            </li>
          ) : (
            <li className="nav-item">
              <Link to="/login" className="nav-link">Login</Link>
            </li>
          )} */}
          
        </ul>
      </nav>
      <nav className="mobile-nav d-lg-none">
      <ul>
        <li className="active"  onClick={handleClick}><NavLink to="/">Home</NavLink></li>
        <li className="">
          <NavLink to="/AboutUs"  onClick={handleClick}>About</NavLink>
          
        </li>
        <li className="">
        <NavLink to="/JoinUs"  onClick={handleClick}>Join Us</NavLink>
          
        </li>
        <li><NavLink to="/Spotlight" onClick={handleClick}>Spotlight</NavLink></li>
        <li><NavLink to="/Chapter" onClick={handleClick}>Chapter & SIG</NavLink></li>
        <li><NavLink to="/Contact" activeclassname="active"  onClick={handleClick}>Contact</NavLink></li>
        <li>
      <NavLink  onClick={handleClick2}>
        Members Login <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </NavLink>
      {isOpen && (
        <div className="dropdown-content">
          {/* Your dropdown content here */}
          {/* For example: */}
          <NavLink to="/NewLogin" onClick={handleClick}>Login</NavLink>
          <NavLink to="/profile" onClick={handleClick}>Profile</NavLink>
          {/* Add more NavLink components for other dropdown items */}
        </div>
      )}
    </li>
      </ul>
    </nav>

    </div>
  </header>
  );
}

export default Header;
