import React, { useEffect, useState , useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Newloginstyle.css';

const Newlogin = () => {
  const navigate = useNavigate();
  const [resendDisabled, setResendDisabled] = useState(false);
 
  // useEffect(() => {
  //   function handleKeyDown(event, index) {
      const [otp,setOpt] = useState (["","","","","",""]);
      const inputsRefs = useRef([]);

      const handelChange = (index, value) => {
      const newOtp = [...otp];
      newOtp[index]= value;

      if (value && index < otp.length -1){
      inputsRefs.current[index + 1].focus();
      }

      setOpt(newOtp);
    };
    const handelKeyDown = (index, e) => {
      if (e.key === "Backspace" && index > 0 && !otp[index]) {
        const newOtp = [...otp];
        newOtp[index - 1] = ""; // Clear the current and previous input
        inputsRefs.current[index - 1].focus(); // Focus on the previous input
        setOpt(newOtp); // Update the OTP state
      }
    };




      
      
    const handleResendClick = () => {
      // Disable the button
      setResendDisabled(true);
  
      // Enable the button after 15 seconds
      setTimeout(() => {
        setResendDisabled(false);
      }, 15000);
    };
  
    const handleSubmitClick = () => {
      // Handle your submit logic here
    };

    

  useEffect(() => {
    const container = document.getElementById('container');
    const registerBtn = document.getElementById('register');
    const loginBtn = document.getElementById('login');
    
    const handleRegisterClick = () => {
      container.classList.add("active");
    };

    const handleLoginClick = () => {
      container.classList.remove("active");
    };

    registerBtn.addEventListener('click', handleRegisterClick);
    loginBtn.addEventListener('click', handleLoginClick);

    // Cleanup the event listeners on component unmount
    return () => {
      registerBtn.removeEventListener('click', handleRegisterClick);
      loginBtn.removeEventListener('click', handleLoginClick);
    };
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  const handleSignInClick = () => {
    // Logic to handle sign-in button click
    navigate('/signin'); // You can replace '/signin' with the actual URL for sign-in
  };

  const handleSignUpClick = () => { 
    // Logic to handle sign-up button click
    navigate('/signup'); // You can replace '/signup' with the actual URL for sign-up
  };
  
 

  const [mobile, setMobile] = useState('');
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [popupHidden, setPopupHidden] = useState(true);

const handleSubmit = async (event) => {
  event.preventDefault();

  // Example of login logic without using an API endpoint
  if (isValidMobile(mobile)) {
    // Simulating a successful login
    setShowVerificationForm(true);
    setPopupHidden(true);
    handleSubmitClick();
  } else {
    // Simulating a failed login
    alert('Invalid mobile number');
    setPopupHidden(false); 
    setShowVerificationForm(false); 
  }
};

const handleSubmitClick2 = () => {
  const otp = document.getElementById('otp').querySelectorAll('input');
  let otpValue = '';
  otp.forEach((digit) => {
    otpValue += digit.value;
  });
  
  // Example of verification logic
  if (otpValue === '123456') { // Change '123456' to your desired OTP
    // Redirect or perform other actions upon successful verification
    alert('Verification successful');
    // Redirect logic goes here
    document.getElementById('header-text').innerText = 'My Account';
    window.location.href = './';
  } else {
    alert('Invalid verification code');
  }
};

const handleResendClick2 = () => {
  // Logic for resending verification code goes here
};

const isValidMobile = (mobile) => {
  // Hardcoded mobile number to validate against
  const validMobileNumber = '1234567890';

  // Check if the provided mobile number matches the valid mobile number
  return mobile === validMobileNumber;
};


  return (
    <div className={`container newlogin ${popupHidden ? 'popup-hidden' : ''}`} id="container">
      <div className="form-container sign-up">
      
      <form className='newform'>
        <h1>Verification Code</h1>
        <span>Please enter the verification code sent to your mobile.</span>
        <div>
          <span>A code has been sent to</span>
          {/* <small>{phoneNumber}</small> */}
          <div id='otp' className='inputs d-flex flex-row justify-content-center mt-2'>
          {otp.map((digit, index) => (
              <input
                key={index}
                className='otp-input m-2 text-center form-control rounded'
                type='text'
                maxLength={1}
                value={digit}
                autoFocus={index === 0}
                ref={(ref) => (inputsRefs.current[index] = ref)}
                onChange={(e) => handelChange(index, e.target.value)}
                onKeyDown={(e) => handelKeyDown(index, e)}
              />
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', marginTop: '20px' }}>
          <button
            type='button'
            onClick={handleResendClick2}
            disabled={resendDisabled}
            style={{ backgroundColor: resendDisabled ? '#eee' : 'rgb(6 2 79)', color: '#fff' }}
          >
            Resend
          </button>
          <button type='button' onClick={handleSubmitClick2}>
            Submit
          </button>
        </div>
      </form>
  
      </div>


      <div className="form-container sign-in">
        <form className='newform' onSubmit={handleSubmit}>
          <h1>Sign In</h1>
{/* 
          <button id="google-signin-button">
          <img src="https://freepngimg.com/thumb/google/66274-school-google-pearl-button-up-sign-middle.png" alt="Google Icon" width="20" height="20"></img>
          Sign in with Google
             </button> */}
          
          <span>Hello, Welcome Back!</span>

                
                <div className="input-wrapper">
                <span className="country-code">+91</span>
                <input className="newinput" type="tel" placeholder='Enter your phone no:' maxLength="10" value={mobile}
          onChange={(event) => setMobile(event.target.value)} />
                </div>
                <button
  type="submit"
  id="register"

>
  Request OTP
</button>
        </form>
      </div>

      
      <div className="toggle-container">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1></h1>
            <p></p>
            <button className="hidden" id="login">Retry</button>
          </div>
          <div className="toggle-panel toggle-right">
            <h1></h1>
            <p></p>
            {/* <button className="hidden" ></button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newlogin;
